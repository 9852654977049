import React, { Component } from 'react';
import { Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import ReactGA4 from 'react-ga4';
import {
    changeCurrentSection,
} from "../../../actions/generalActions";
import arrowLeft from '../../images/arrow-left.svg';
import calendar from '../../images/calendar.svg';
import flowrun from '../../images/flowrunicon.png';
import plugin from '../../images/PluginTraceLog.svg';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';

const texts = [
    "dotnet --version",
    `git clone https://github.com/coowise/OllamaDataverseEntityChatApp
cd OllamaDataverseEntityChatApp`,
    `<?xml version="1.0" encoding="utf-8" ?>  
<configuration>  
  <appSettings>  
    <!-- Replace with your Dataverse connection string -->  
    <add key="DataverseConnectionString" value="Enter your Dataverse connection string here (AuthType, URL, ClientId, and ClientSecret)" />  

    <!-- Specify the AI model to be used for chat generation (e.g., llama3) -->
    <add key="AIGenerationModel" value="Enter the name of the AI model here (e.g., llama3)" />

    <!-- Specify the AI model to be used for embedding generation (e.g., nomic-embed-text) -->
    <add key="AIEmbeddingModel" value="Enter the name of the AI model here (e.g., nomic-embed-text)" />

    <!-- Replace with the host IP where Ollama is running -->  
    <add key="OllamaHost" value="Enter Ollama host IP address here" />  

    <!-- Replace with the port where Ollama is running -->  
    <add key="OllamaPort" value="Enter Ollama port number here" />  

    <!-- Specify the maximum number of records to process -->  
    <add key="MaxRecords" value="10" />  
  </appSettings>  
</configuration>  
`,
    `public static async Task<EntityMetadata> GetEntityMetadata(ServiceClient serviceClient, string entityLogicalName)
{
    var request = new RetrieveEntityRequest
    {
        EntityFilters = EntityFilters.Attributes,
        LogicalName = entityLogicalName
    };

    var response = (RetrieveEntityResponse)await serviceClient.ExecuteAsync(request);
    return response.EntityMetadata;
}
`,
    `private const string EMBEDDINGS_CACHE_PATH = "embeddings_cache.json";

var httpClient = new HttpClient
{
    Timeout = TimeSpan.FromMinutes(5),
    BaseAddress = uri
};

// Fetch available models
var models = await ollamaApiClient.ListLocalModelsAsync();
string embedModel = models.FirstOrDefault(m => m.Name.Contains(ConfigurationManager.AppSettings["AIEmbeddingModel"]))?.Name;

// Set up the embed client with the selected model
var embedOllama = new OllamaApiClient(httpClient)
{
    SelectedModel = embedModel
};

// Create a consolidated context of all records
var allRecordSummaries = results.Entities
    .Take(Convert.ToInt32(ConfigurationManager.AppSettings["MaxRecords"]))
    .Select((record, index) => /* Processing logic for record */)
    .ToList();

const int CHUNK_SIZE = 1;
var totalRecords = results.Entities.Count;
var chunks = new List<List<string>>();

for (int i = 0; i < allRecordSummaries.Count; i += CHUNK_SIZE)
{
    chunks.Add(allRecordSummaries.Skip(i).Take(CHUNK_SIZE).ToList());
}

// Store embeddings for each chunk
var shouldCreateEmbeddings = true;
Dictionary<int, (List<string> text, float[] embedding)> chunkEmbeddings = new();

if (File.Exists(EMBEDDINGS_CACHE_PATH))
{
    var cache = JsonSerializer.Deserialize<EmbeddingsCache>(File.ReadAllText(EMBEDDINGS_CACHE_PATH));
    if (cache.EntityName == entity)
    {
        Console.WriteLine("\rLoading embeddings from cache...");
        chunkEmbeddings = cache.Embeddings.ToDictionary(
            kvp => kvp.Key,
            kvp => (kvp.Value.Text, kvp.Value.Embedding)
        );
        shouldCreateEmbeddings = false;
    }
}

if (shouldCreateEmbeddings)
{
    Console.WriteLine("Creating embeddings...");
    chunkEmbeddings = new Dictionary<int, (List<string> text, float[] embedding)>();

    var isMultiThread = Convert.ToBoolean(ConfigurationManager.AppSettings["MultiThreadEmbedding"]);
    if (isMultiThread)
    {
        var tasks = new List<Task<(int index, float[] embedding)>>();
        Console.WriteLine("Processing chunks in parallel...");

        // Create tasks for parallel processing
        for (int i = 0; i < chunks.Count; i++)
        {
            var index = i;
            var chunk = chunks[i];
            var chunkText = string.Join("\n", chunk);

            tasks.Add(Task.Run(async () =>
            {
                var embeddingResult = await embedOllama.GenerateEmbeddingAsync(chunkText);
                return (index, embeddingResult.Vector.ToArray());
            }));
        }

        // Show spinning cursor while processing
        var spinChars = new[] { '|', '/', '-', '\\' };
        var spinIndex = 0;

        while (tasks.Any(t => !t.IsCompleted))
        {
            Console.Write($"\rProcessing... {spinChars[spinIndex]} ({tasks.Count(t => t.IsCompleted)}/{tasks.Count} chunks complete)");
            spinIndex = (spinIndex + 1) % spinChars.Length;
            await Task.Delay(100);
        }

        // Store results
        var embeddingResults = await Task.WhenAll(tasks);
        foreach (var (index, embedding) in embeddingResults)
        {
            chunkEmbeddings.Add(index, (chunks[index], embedding));
        }

        Console.WriteLine("\rAll chunks processed successfully!");
    }
    else
    {
        for (int i = 0; i < chunks.Count; i++)
        {
            var chunkText = string.Join("\n", chunks[i]);
            var embeddingResult = await embedOllama.GenerateEmbeddingAsync(chunkText);
            var embedding = embeddingResult.Vector.ToArray();

            chunkEmbeddings.Add(i, (chunks[i], embedding));
        }
    }

    // Save embeddings to cache
    var cache = new EmbeddingsCache
    {
        EntityName = entity,
        Embeddings = chunkEmbeddings.ToDictionary(
            kvp => kvp.Key,
            kvp => new CacheEntry
            {
                Text = kvp.Value.text,
                Embedding = kvp.Value.embedding
            }
        )
    };

    File.WriteAllText(EMBEDDINGS_CACHE_PATH, JsonSerializer.Serialize(cache));
}

Console.WriteLine("Embeddings created! ✓");
`,
    `var systemPrompt = File.ReadAllText(Path.Combine(AppDomain.CurrentDomain.BaseDirectory, "Prompts", "SystemPrompt.txt"));

await foreach (var token in chat.SendAsAsync(OllamaSharp.Models.Chat.ChatRole.System, systemPrompt))
{
    // Optional: Show progress of system message
    // You can log or display the token here if needed
}
`,
    `while (true)
{
    Console.Write("\nAsk a question (or 'exit' to quit): ");
    var message = Console.ReadLine();
    if (message?.ToLower() == "exit") break;

    var similarityMethod = ConfigurationManager.AppSettings["SimilarityMethod"];
    List<(List<string> text, double score)> relevantChunks;

    if (similarityMethod?.ToLower() == "bm25")
    {
        // Convert chunks for BM25 format
        var textChunks = chunkEmbeddings.ToDictionary(
            kvp => kvp.Key,
            kvp => kvp.Value.text
        );

        relevantChunks = OllamaManager.FindMostRelevantChunksBM25(
            message,
            textChunks,
            topK: 3
        );
    }
    else // Default to Cosine similarity
    {
        var questionEmbedding = await embedOllama.GenerateEmbeddingAsync(message);
        var cosineChunks = OllamaManager.FindMostRelevantChunks(
            questionEmbedding.Vector.ToArray(),
            chunkEmbeddings,
            topK: 3
        );

        // Convert to common format
        relevantChunks = cosineChunks.Select(c => 
            (c.text, (double)OllamaManager.CosineSimilarity(questionEmbedding.Vector.ToArray(), c.embedding))
        ).ToList();
    }

    // Construct the context from relevant chunks
    var context = string.Join("\n", relevantChunks.SelectMany(c => c.text));

    // Send the question and context to the chat
    await foreach (var answerToken in chat.SendAsync($"Context:\n{context}\n\nQuestion: {message}"))
    {
        Console.Write(answerToken);
    }
}
`];

const delay = ms => new Promise(res => setTimeout(res, ms));

const llaveizquierda = "{";
const llavederecha = "}";
const parentesisizquierdo = "(";
const parentesisderecho = ")";
const menor = "<";
const mayor = ">";

class OllamaDataverseImplementation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            codeCopied: 0
        }

        this.changeSection = this.changeSection.bind(this);
        this.onClickCopy = this.onClickCopy.bind(this);
    }

    changeSection() {
        this.props.changeCurrentSection("Blog");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "Blog", page: '/Blog' });
    }

    async onClickCopy(codeCopied, isJson) {
        if (isJson)
            navigator.clipboard.writeText(JSON.stringify(texts[codeCopied - 1], null, 2));
        else
            navigator.clipboard.writeText(texts[codeCopied - 1]);

        this.setState({ codeCopied: codeCopied });

        await delay(2000);

        this.setState({ codeCopied: 0 });
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Integrating Ollama to Microsoft Dataverse: Technical implementation</title>
                    <meta name="description" content="Learn how to build an intelligent data analysis tool by combining Ollama's AI capabilities with Microsoft Dataverse data." />
                    <meta name="keywords" content="ollama, .NET, C#, AI, NLP, console application, language model, artificial intelligence, dataverse" />
                    <meta property="og:title" content="Integrating Ollama to Microsoft Dataverse: Technical implementation" />
                    <meta property="og:description" content="Learn how to build an intelligent data analysis tool by combining Ollama's AI capabilities with Microsoft Dataverse data." />
                    <meta property="og:type" content="article" />
                    <meta property="article:published_time" content="2024-12-27" />
                </Helmet>
                <section class="article-section">
                    <div className='article-container'>
                        <div className='article-header'>
                            <a href='/blog'>
                                <div className='back-button-container'>
                                    <img src={arrowLeft} />
                                    <label>Back to Articles</label>
                                </div>
                            </a>
                        </div>
                        <div className='article'>
                            <h1>Integrating Ollama to Microsoft Dataverse: Technical implementation</h1>
                            <div className='author-date-container'>
                                <div className='general-container'>
                                    <img src={calendar} />
                                    <label>December 27, 2024</label>
                                </div>
                            </div>
                            <div className='article-div'>
                                <p>
                                    Earlier this week, we released <a href='/article#ollama-with-dataverse-overview' target='_blank'>Integrating Ollama to Microsoft Dataverse: An Overview</a> for connecting a local LLM framework to Microsoft Dataverse.
                                    Now, we're taking it a step further, and sharing the step-by-step that turns theory into action. 
                                    In this article, we'll dive into the technical implementation, walking through the steps to bring the concept to life.
                                </p>
                                <p className='note'>
                                    <span className='fw600'>Note:</span> This implementation serves as a proof of concept to demonstrate what's possible with Ollama and Dataverse. 
                                    While suitable for small datasets, working with larger datasets will require integrating a vector database to store and retrieve embeddings. 
                                    Nevertheless, it's an engaging project that can provide valuable insights through natural language queries about your data.
                                </p>
                                <h2>Prerequisites</h2>
                                <div className='step'>
                                    <p className='mb-05'>
                                        Before you begin, make sure you have the following installed:
                                    </p>
                                    <ul class="custom-bullets">
                                        <li>.NET SDK (version 8.0)</li>
                                        <li>Visual Studio or any editor of your choice</li>
                                        <li>
                                            Ollama available and accessible. You can set it up locally, on a server, or in a container, depending on your preference.
                                            If you choose to install it on your machine, refer to our previous article, <a href='/article#install-and-run-ollama-net' target='_blank'>Getting started with Ollama in .NET</a>, for detailed instructions.
                                        </li>
                                    </ul>
                                    <p>You can use the following command to verify the installed version of .NET.</p>
                                    <div className='bash'>
                                        <div className='bash-header'>
                                            <label>bash</label>
                                            <Button onClick={() => this.onClickCopy(1, false)}>
                                                <Icon className={this.state.codeCopied == 1 ? 'check' : ''} name={this.state.codeCopied == 1 ? 'check' : 'copy outline'} />
                                                <p>{this.state.codeCopied == 1 ? ' Copied!' : 'Copy'}</p>
                                            </Button>
                                        </div>
                                        <div className='bash-body'>
                                            <p>dotnet --version</p>
                                        </div>
                                    </div>
                                </div>
                                <h3>Step 1: Clone the repository</h3>
                                <div className='step'>
                                    <p>Start by cloning the repository for this project, which contains all the necessary files and configurations:</p>
                                    <div className='bash mt-10'>
                                        <div className='bash-header'>
                                            <label>bash</label>
                                            <Button onClick={() => this.onClickCopy(2, false)}>
                                                <Icon className={this.state.codeCopied == 2 ? 'check' : ''} name={this.state.codeCopied == 2 ? 'check' : 'copy outline'} />
                                                <p>{this.state.codeCopied == 2 ? ' Copied!' : 'Copy'}</p>
                                            </Button>
                                        </div>
                                        <div className='bash-body'>
                                            <p>git clone https://github.com/coowise/OllamaDataverseEntityChatApp</p>
                                            <p><span className='orange'>cd</span> OllamaDataverseEntityChatApp</p>
                                        </div>
                                    </div>
                                    <p>This repository includes pre-configured files to streamline your setup process.</p>
                                </div>
                                <h3>Step 2: Configure the App.config File</h3>
                                <div className='step'>
                                    <p>
                                        In the project directory, configure the <span>App.config</span> file to store your Dataverse connection, Ollama API endpoint, and settings. 
                                        Here’s an example structure:
                                    </p>
                                    <div className='bash mt-10'>
                                        <div className='bash-header'>
                                            <label>bash</label>
                                            <Button onClick={() => this.onClickCopy(3, false)}>
                                                <Icon className={this.state.codeCopied == 3 ? 'check' : ''} name={this.state.codeCopied == 3 ? 'check' : 'copy outline'} />
                                                <p>{this.state.codeCopied == 3 ? ' Copied!' : 'Copy'}</p>
                                            </Button>
                                        </div>
                                        <div className='bash-body'>
                                            <p><span className='grey'>&lt;?xml version=</span><span className='green'>"1.0"</span> <span className='grey'>encoding=</span><span className='green'>"utf-8"</span> <span className='grey'>?&gt;</span></p>
                                            <p>&lt;configuration&gt;</p>
                                            <p className='one-space'>&lt;appSettings&gt;</p>
                                            <p className='two-spaces'><span className='grey'><code>&lt;!--</code> Replace with your Dataverse connection string <code>--&gt;</code></span></p>
                                            <p className='two-spaces'>&lt;add <span className='red'>key</span>=<span className='green'>"DataverseConnectionString"</span> <span className='red'>value</span>=<span className='green'>"Enter your Dataverse connection string here (AuthType, URL, ClientId, and ClientSecret)"</span> /&gt;</p>
                                            <br></br>
                                            <p className='two-spaces'><span className='grey'><code>&lt;!--</code> Specify the AI model to be used for chat generation (e.g., llama3) <code>--&gt;</code></span></p>
                                            <p className='two-spaces'>&lt;add <span className='red'>key</span>=<span className='green'>"AIGenerationModel"</span> value=<span className='green'>"Enter the name of the AI model here (e.g., llama3)"</span> /&gt;</p>
                                            <br></br>
                                            <p className='two-spaces'><span className='grey'><code>&lt;!--</code> Specify the AI model to be used for embedding generation (e.g., nomic-embed-text) <code>--&gt;</code></span></p>
                                            <p className='two-spaces'>&lt;add <span className='red'>key</span>=<span className='green'>"AIEmbeddingModel"</span> value=<span className='green'>"Enter the name of the AI model here (e.g., nomic-embed-text)"</span> /&gt;</p>
                                            <br></br>
                                            <p className='two-spaces'><span className='grey'><code>&lt;!--</code> Replace with the host IP where Ollama is running <code>--&gt;</code></span></p>
                                            <p className='two-spaces'>&lt;add <span className='red'>key</span>=<span className='green'>"OllamaHost"</span> <span className='red'>key</span>=<span className='green'>"Enter Ollama host IP address here"</span> /&gt;</p>
                                            <br></br>
                                            <p className='two-spaces'><span className='grey'><code>&lt;!--</code> Replace with the port where Ollama is running <code>--&gt;</code></span></p>
                                            <p className='two-spaces'>&lt;add <span className='red'>key</span>=<span className='green'>"OllamaPort"</span> <span className='red'>key</span>=<span className='green'>"Enter Ollama port number here"</span> /&gt;</p>
                                            <br></br>
                                            <p className='two-spaces'><span className='grey'><code>&lt;!--</code> Specify the maximum number of records to process <code>--&gt;</code></span></p>
                                            <p className='two-spaces'>&lt;add <span className='red'>key</span>=<span className='green'>"MaxRecords"</span> <span className='red'>key</span>=<span className='green'>"10"</span> /&gt;</p>
                                            <br></br>
                                            <p className='two-spaces'><span className='grey'><code>&lt;!--</code> Enable or disable multi-threading for embedding generation <code>--&gt;</code></span></p>
                                            <p className='two-spaces'><span className='grey'><code>&lt;!--</code> If set to 'true', embeddings will be created in parallel for faster processing. Use 'false' for sequential execution. <code>--&gt;</code></span></p>
                                            <p className='two-spaces'>&lt;add <span className='red'>key</span>=<span className='green'>"MultiThreadEmbedding"</span> <span className='red'>key</span>=<span className='green'>"true"</span> /&gt;</p>
                                            <br></br>
                                            <p className='two-spaces'><span className='grey'><code>&lt;!--</code> Options: "Cosine" or "BM25" <code>--&gt;</code></span></p>
                                            <p className='two-spaces'><span className='grey'><code>&lt;!--</code> Determines the similarity method used for finding the most relevant content. <code>--&gt;</code></span></p>
                                            <p className='three-spaces'><span className='grey'>- 'Cosine': Compares embedding vectors (Semantic match).</span></p>
                                            <p className='three-spaces'><span className='grey'>- 'BM25': Uses a text-based (keyword match) retrieval approach for relevance scoring. <code>--&gt;</code></span></p>
                                            <p className='two-spaces'>&lt;add <span className='red'>key</span>=<span className='green'>"SimilarityMethod"</span> <span className='red'>key</span>=<span className='green'>"BM25"</span> /&gt;</p>
                                            <p className='one-space'>&lt;/appSettings&gt;</p>
                                            <p>&lt;/configuration&gt;</p>
                                        </div>
                                    </div>
                                    <p>Update the values according to your environment.</p>
                                    <p className='note important'>
                                        <span className='fw600'>⚠️ Important:</span> The nomic-embed-text model uses a 768-dimensional embedding vector. It is used because it provides a good balance between performance and accuracy on average or low-performance hardware. Models with higher dimensions, like llama3, require more computational resources and are better suited for higher-performance hardware.
                                    </p>
                                </div>
                                <h3>Step 3: Working with Entity Metadata and Query Construction</h3>
                                <div className='step'>
                                    <p>
                                        In this step, we retrieve the metadata of Dataverse entities and build queries based on that metadata,
                                        allowing us to interact with entities like <span className='fw600'>Flow Runs</span> and <span className='fw600'>Plugin Trace Logs</span> through Ollama.
                                    </p>
                                    <p><span className='bold'>Retrieving Entity Metadata</span></p>
                                    <p>
                                        To query data effectively, we first fetch the metadata of an entity, which includes information about its fields and relationships.
                                        Here’s how to retrieve it:
                                    </p>
                                    <div className='bash mt-10'>
                                        <div className='bash-header'>
                                            <label>bash</label>
                                            <Button onClick={() => this.onClickCopy(4, false)}>
                                                <Icon className={this.state.codeCopied == 4 ? 'check' : ''} name={this.state.codeCopied == 4 ? 'check' : 'copy outline'} />
                                                <p>{this.state.codeCopied == 4 ? ' Copied!' : 'Copy'}</p>
                                            </Button>
                                        </div>
                                        <div className='bash-body'>
                                            <p><span className='blue'>public static async</span> {"Task<EntityMetadata>"} <span className='red'>GetEntityMetadata</span>{parentesisizquierdo}ServiceClient serviceClient, <span className='orange'>string</span> entityLogicalName{parentesisderecho}</p>
                                            <p>{llaveizquierda}</p>
                                            <p className='one-space'><span className='blue'>var</span> request = <span className='blue'>new</span> RetrieveEntityRequest</p>
                                            <p className='one-space'>{llaveizquierda}</p>
                                            <p className='two-spaces'>EntityFilters = EntityFilters.Attributes,</p>
                                            <p className='two-spaces'>LogicalName = entityLogicalName</p>
                                            <p className='one-space'>{llavederecha};</p>
                                            <br></br>
                                            <p className='one-space'><span className='blue'>var</span> response = (RetrieveEntityResponse) <span className='blue'>await</span> serviceClient.ExecuteAsync(request);</p>
                                            <p className='one-space'><span className='blue'>return</span> response.EntityMetadata;</p>
                                            <p>{llavederecha}</p>
                                        </div>
                                    </div>
                                    <p><span className='bold'>Why Flow Runs and Plugin Trace Logs?</span></p>
                                    <ul className='custom-bullets'>
                                        <li>
                                            <span>Static Data:</span> Both <span>Flow Runs</span> and <span>Plugin Trace Logs</span> consist of records that don’t change over time, making them ideal for embedding into vectors and processing with Ollama. This improves performance since embeddings don’t need updates.
                                        </li>
                                        <li>
                                            <span>Structured Data:</span> These entities have well-defined fields like <span className='pd'>messageblock</span> and <span className='pd'>exceptiondetails</span>, which makes it easy to create focused queries for retrieving relevant records.
                                        </li>
                                        <li>
                                            <span>Use Case:</span> They’re useful for error tracking and workflow monitoring, providing clear, actionable data that can be queried with natural language.
                                        </li>
                                    </ul>
                                    <p><span className='bold'>Building Queries</span></p>
                                    <p>Let's examine the datasets we're working with for both entities:</p>
                                    <div className='blog-alternative-table-container'>
                                        <div className='blog-alternative-table'>
                                            <div className='alternative-table-header'>
                                                <img src={flowrun}/>
                                                <h2>Flow Run</h2>
                                            </div>
                                            <div className='overflow-x-auto'>
                                                <table>
                                                    <p>Main Entity Fields</p>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Fields
                                                            </th>
                                                            <th>
                                                                Description
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>flowrunid</td>
                                                            <td>Unique identifier for the flow execution</td>
                                                        </tr>
                                                        <tr>
                                                            <td>name</td>
                                                            <td>Name of the flow instance</td>
                                                        </tr>
                                                        <tr>
                                                            <td>createdon</td>
                                                            <td>Record creation timestamp</td>
                                                        </tr>
                                                        <tr>
                                                            <td>status</td>
                                                            <td>Current execution status (succeeded / failed)</td>
                                                        </tr>
                                                        <tr>
                                                            <td>starttime</td>
                                                            <td>When the flow execution started</td>
                                                        </tr>
                                                        <tr>
                                                            <td>endtime</td>
                                                            <td>When the flow execution completed</td>
                                                        </tr>
                                                        <tr>
                                                            <td>duration</td>
                                                            <td>Total execution time in milliseconds</td>
                                                        </tr>
                                                        <tr>
                                                            <td>triggertype</td>
                                                            <td>What initiated the flow (manual / automated)</td>
                                                        </tr>
                                                    </tbody>
                                                    <p className='mt-15'>Related Workflow Fields</p>  
                                                    <tbody>
                                                        <tr className='nb'>
                                                            <td>name</td>
                                                            <td>Name of the flow definition</td>
                                                        </tr>
                                                        <tr>
                                                            <td>primaryentity</td>
                                                            <td>Main entity the flow operates on</td>
                                                        </tr>
                                                        <tr>
                                                            <td>description</td>
                                                            <td>Flow's purpose and functionality</td>
                                                        </tr>
                                                        <tr>
                                                            <td>statecode</td>
                                                            <td>Current state of the flow (active / inactive)</td>
                                                        </tr>
                                                        <tr>
                                                            <td>category</td>
                                                            <td>Type of process (i.e. Modern Flow, Workflow, etc)</td>
                                                        </tr>
                                                        <tr>
                                                            <td>clientdata</td>
                                                            <td>Flow’s internal definition (triggers, actions, connections, and associated metadata)</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className='blog-alternative-table'>
                                            <div className='alternative-table-header'>
                                                <img src={plugin} className='plugin'/>
                                                <h2>Plugin Trace Log</h2>
                                            </div>
                                            <div className='overflow-x-auto'>
                                                <table>
                                                    <p>Main Entity Fields</p>
                                                    <thead>
                                                        
                                                        <tr>
                                                            <th>
                                                                Fields
                                                            </th>
                                                            <th>
                                                                Description
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>createdon</td>
                                                            <td>When the log entry was created</td>
                                                        </tr>
                                                        <tr>
                                                            <td>messagename</td>
                                                            <td>Type of operation being logged</td>
                                                        </tr>
                                                        <tr>
                                                            <td>messageblock</td>
                                                            <td>Detailed log message content</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{window.innerWidth < 767 ? "exception details" : "exceptiondetails"}</td>
                                                            <td>Error details if operation failed</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='truncated'>{window.innerWidth < 767 ? "performance execution duration" : "performanceexecutionduration"}</td>
                                                            <td>How long the operation took to execute</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='truncated'>operationtype</td>
                                                            <td>Plugin, Workflow Activity, etc</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='truncated'>messagename</td>
                                                            <td>Create, Update, Delete, Associate, Retrieve, etc</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='truncated'>typename</td>
                                                            <td>Plugin Name</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <p className='note'>
                                        <span className='fw600'>Note:</span> Both queries are ordered by createdon in descending order to ensure the most recent records are retrieved first.
                                    </p>
                                </div>
                                <h3>Step 4: Creating Embeddings for Entity Records</h3>
                                <div className='step'>
                                    <p>
                                        In this step, we will create embeddings for the records retrieved from Microsoft Dataverse.
                                        If embeddings have already been generated previously, we will load them from a cache to avoid redundant computation.
                                        If not, we will generate the embeddings and store them for future use.
                                    </p>
                                    <p><span className='bold'>Chunk Records for Embedding:</span></p>
                                    <p>
                                        To handle large volumes of data efficiently, the records are split into smaller chunks.
                                        Each chunk will contain a portion of the records retrieved from Dataverse.
                                    </p>
                                    <p>The <span>MaxRecords</span> setting from the <span>App.config</span> is used to limit how many records to process.</p>
                                    <p><span className='bold'>Check for Existing Embedding Cache:</span></p>
                                    <p>
                                        Before generating new embeddings, the system checks if a cache file already exists (stored as <span>EMBEDDINGS_CACHE_PATH</span>).
                                        If the cache exists and contains embeddings for the same entity, it will load the embeddings to avoid re-processing.
                                    </p>
                                    <p><span className='bold'>Generate Embeddings for New Records:</span></p>
                                    <p>If no cache is found or the cache is outdated, embeddings are generated by sending the text from the chunks to the Ollama API.</p>
                                    <p>The <span>embedOllama.GenerateEmbeddingAsync()</span> method is used to generate embeddings. This method returns an embedding vector, which is a numeric representation of the text.</p>
                                    <p><span className='bold'>Store Embeddings in Cache:</span></p>
                                    <p>
                                        Once embeddings are generated, they are stored in a cache file (JSON format).
                                        This cache will be used for subsequent runs to avoid re-calculating embeddings for the same entity.
                                    </p>
                                    <p>The cache includes both the text of the records and the corresponding embeddings.</p>
                                    <div className='bash mt-10'>
                                        <div className='bash-header'>
                                            <label>bash</label>
                                            <Button onClick={() => this.onClickCopy(5, false)}>
                                                <Icon className={this.state.codeCopied == 5 ? 'check' : ''} name={this.state.codeCopied == 5 ? 'check' : 'copy outline'} />
                                                <p>{this.state.codeCopied == 5 ? ' Copied!' : 'Copy'}</p>
                                            </Button>
                                        </div>
                                        <div className='bash-body'>
                                            <p><span className='blue'>private const</span> <span className='orange'>string</span> EMBEDDINGS_CACHE_PATH = <span className='green'>"embeddings_cache.json"</span>;</p>
                                            <br></br>
                                            <p><span className='blue'>var</span> httpClient = <span className='blue'>new</span> HttpClient()</p>
                                            <p>{llaveizquierda}</p>
                                            <p className='one-space'>Timeout = TimeSpan.FromMinutes(<span className='red'>5</span>),</p>
                                            <p className='one-space'>BaseAddress = uri</p>
                                            <p>{llavederecha};</p>
                                            <br></br>
                                            <p><span className='grey'>// Fetch available models</span></p>
                                            <p><span className='blue'>var</span> models = <span className='blue'>await</span> ollamaApiClient.ListLocalModelsAsync();</p>
                                            <p><span className='orange'>string</span> embedModel = models.FirstOrDefault(m <code className='white'>={mayor}</code> m.Name.Contains(ConfigurationManager.AppSettings[<span className='green'>"AIEmbeddingModel"</span>]))?.Name;</p>
                                            <br></br>
                                            <p><span className='grey'>// Set up the embed client with the selected model</span></p>
                                            <p><span className='blue'>var</span> embedOllama = <span className='blue'>new</span> OllamaApiClient(httpClient)</p>
                                            <p>{llaveizquierda}</p>
                                            <p className='one-space'>SelectedModel = embedModel</p>
                                            <p>{llavederecha};</p>
                                            <br></br>
                                            <p><span className='grey'>// Create a consolidated context of all records</span></p>
                                            <p><span className='blue'>var</span> allRecordSummaries = results.Entities</p>
                                            <p className='one-space'>.Take(Convert.ToInt32(ConfigurationManager.AppSettings[<span className='green'>"MaxRecords"</span>]))</p>
                                            <p className='one-space'>.Select((<span className='blue'>record</span>, <span className='red'>index</span>) <code className='white'>={mayor}</code> <span className='grey'>/* Processing logic for record */</span>)</p>
                                            <p className='one-space'>.ToList();</p>
                                            <br></br>
                                            <p className=''><span className='blue'>const</span> <span className='blue'>int</span> CHUNK_SIZE = <span className='red'>1</span></p>
                                            <p><span className='blue'>var</span> totalRecords = results.Entities.Count;</p>
                                            <p><span className='blue'>var</span> chunks = <span className='blue'>new</span> List{menor}List{menor}<span className='orange'>string</span>{mayor}{mayor}();</p>
                                            <br></br>
                                            <p className=''><span className='blue'>for</span> (<span className='orange'>int</span> i = <span className='red'>0</span>; i {menor} allRecordSummaries.Count; i += CHUNK_SIZE)</p>
                                            <p>{llaveizquierda}</p>
                                            <p className='one-space'>chunks.Add(allRecordSummaries.Skip(i).Take(CHUNK_SIZE).ToList());</p>
                                            <p>{llavederecha}</p>
                                            <br></br>
                                            <p><span className='grey'>// Store embeddings for each chunk</span></p>
                                            <p><span className='blue'>var</span> shouldCreateEmbeddings = <span className='blue'>true</span>;</p>
                                            <p>Dictionary{menor}<span className='orange'>int</span>, (List{menor}<span className='orange'>string</span>{mayor} text, <span className='orange'>float</span>[] embedding){mayor} chunkEmbeddings = <span className='blue'>new</span>();</p>
                                            <br></br>
                                            <p><span className='blue'>if</span> (File.Exists(EMBEDDINGS_CACHE_PATH))</p>
                                            <p>{llaveizquierda}</p>
                                            <p className='one-space'><span className='blue'>var</span> cache = JsonSerializer.Deserialize{menor}EmbeddingsCache{mayor}(File.ReadAllText(EMBEDDINGS_CACHE_PATH));</p>
                                            <p className='one-space'><span className='blue'>if</span> (cache.EntityName == entity)</p>
                                            <p className='one-space'>{llaveizquierda}</p>
                                            <p className='two-spaces'>Console.WriteLine(<span className='green'>"\rLoading embeddings from cache..."</span>);</p>
                                            <p className='two-spaces'>chunkEmbeddings = cache.Embeddings.ToDictionary(</p>
                                            <p className='three-spaces'>kvp <code className='white'>={mayor}</code> kvp.Key,</p>
                                            <p className='three-spaces'>kvp <code className='white'>={mayor}</code> (kvp.Value.Text, kvp.Value.Embedding)</p>
                                            <p className='two-spaces'>);</p>
                                            <p className='two-spaces'>shouldCreateEmbeddings = <span className='blue'>false</span>;</p>
                                            <p className='one-space'>{llavederecha}</p>
                                            <p>{llavederecha}</p>
                                            <br></br>
                                            <p><span className='blue'>if</span> (shouldCreateEmbeddings)</p>
                                            <p>{llaveizquierda}</p>
                                            <p className='one-space'>Console.WriteLine(<span className='green'>"Creating embeddings..."</span>);</p>
                                            <p className='one-space'>chunkEmbeddings = <span className='blue'>new</span> Dictionary{menor}<span className='orange'>int</span>, (List{menor}<span className='orange'>string</span>{mayor} text, <span className='orange'>float</span>[] embedding){mayor}{mayor}();</p>
                                            <p className='one-space'><span className='blue'>var</span> isMultiThread = Convert.ToBoolean(ConfigurationManager.AppSettings[<span className='green'>"MultiThreadEmbedding"</span>]);</p>
                                            <br></br>
                                            <p className='one-space'><span className='blue'>if</span> (isMultiThread)</p>
                                            <p className='one-space'>{llaveizquierda}</p>
                                            <p className='two-spaces'><span className='blue'>var</span> tasks = <span className='blue'>new</span> List{menor}Task{menor}(<span className='orange'>int</span> index, <span className='orange'>float</span>[] embedding){mayor}{mayor}();</p>
                                            <p className='two-spaces'>Console.WriteLine(<span className='green'>"Processing chunks in parallel..."</span>);</p>
                                            <br></br>
                                            <p className='two-spaces'><span className='grey'>// Create tasks for parallel processing"</span></p>
                                            <p className='two-spaces'><span className='blue'>for</span> (<span className='orange'>int</span> i = <span className='red'>0</span>; i {menor} chunks.Count; i++)</p>
                                            <p className='two-spaces'>{llaveizquierda}</p>
                                            <p className='three-spaces'><span className='blue'>var</span> index = i;</p>
                                            <p className='three-spaces'><span className='blue'>var</span> chunk = chunks[i];</p>
                                            <p className='three-spaces'><span className='blue'>var</span> chunkText = <span className='orange'>string</span>.Join(<span className='green'>"\n"</span>, chunk);</p>
                                            <br></br>
                                            <p className='three-spaces'>tasks.Add{parentesisizquierdo}Task.Run{parentesisizquierdo}<span className='blue'>async</span> () <code className='white'>={mayor}</code></p>
                                            <p className='three-spaces'>{llaveizquierda}</p>
                                            <p className='four-spaces'><span className='blue'>var</span> embeddingResult = <span className='blue'>await</span> embedOllama.GenerateEmbeddingAsync(chunkText);</p>
                                            <p className='four-spaces'><span className='blue'>return</span> (index, embeddingResult.Vector.ToArray());</p>
                                            <p className='three-spaces'>{llavederecha}{parentesisderecho}{parentesisderecho};</p>
                                            <p className='two-spaces'>{llavederecha}</p>
                                            <br></br>
                                            <p className='two-spaces'><span className='grey'>// Show spinning cursor while processing</span></p>
                                            <p className='two-spaces'><span className='blue'>var</span> spinChars = <span className='blue'>new</span> [] {llaveizquierda} <span className='green'>'|'</span>, <span className='green'>'/'</span>, <span className='green'>'-'</span>, <span className='green'>'\\'</span> {llavederecha};</p>
                                            <p className='two-spaces'><span className='blue'>var</span> spinIndex = <span className='red'>0</span>;</p>
                                            <br></br>
                                            <p className='two-spaces'><span className='blue'>while</span> (tasks.Any(t <code className='white'>={mayor}</code> !t.IsCompleted))</p>
                                            <p className='two-spaces'>{llaveizquierda}</p>
                                            <p className='three-spaces'>Console.WriteLine(<span className='green'>$"\rProcessing... {llaveizquierda}spinChars[spinIndex]{llavederecha} ({llaveizquierda}tasks.Count(t <code className='green'>={mayor}</code> t.IsCompleted){llavederecha}/{llaveizquierda}tasks.Count{llavederecha} chunks complete)"</span>);</p>
                                            <p className='three-spaces'>spinIndex = (spinIndex + <span className='red'>1</span>) % spinChars.Length;</p>
                                            <p className='three-spaces'><span className='blue'>await</span> Task.Delay(<span className='red'>100</span>);</p>
                                            <p className='two-spaces'>{llavederecha}</p>
                                            <br></br>
                                            <p className='two-spaces'><span className='grey'>// Store results (renamed from 'results' to 'embeddingResults')</span></p>
                                            <p className='two-spaces'><span className='blue'>var</span> embeddingResults = <span className='blue'>await</span> Task.WhenAll(tasks);</p>
                                            <p className='two-spaces'><span className='blue'>foreach</span> (<span className='blue'>var</span> (index, embedding) <span className='blue'>in</span> embeddingResults)</p>
                                            <p className='two-spaces'>{llaveizquierda}</p>
                                            <p className='three-spaces'>chunkEmbeddings.Add(index, (chunks[index], embedding));</p>
                                            <p className='two-spaces'>{llavederecha}</p>
                                            <br></br>
                                            <p className='two-spaces'>Console.WriteLine(<span className='green'>"\rAll chunks processed successfully!"</span>);</p>
                                            <p className='one-space'>{llavederecha}</p>
                                            <p className='one-space'><span className='blue'>else</span></p>
                                            <p className='one-space'>{llaveizquierda}</p>

                                            <p className='two-spaces'><span className='blue'>for</span> (<span className='orange'>int</span> i = <span className='red'>0</span>; i {menor} chunks.Count; i++)</p>
                                            <p className='two-spaces'>{llaveizquierda}</p>
                                            <p className='three-spaces'><span className='blue'>var</span> chunkText = <span className='orange'>string</span>.Join(<span className='green'>"\n"</span>, chunks[i]);</p>
                                            <p className='three-spaces'><span className='blue'>var</span> embeddingResult = <span className='blue'>await</span> embedOllama.GenerateEmbeddingAsync(chunkText);</p>
                                            <p className='three-spaces'><span className='blue'>var</span> embedding = embeddingResult.Vector.ToArray();</p>
                                            <br></br>
                                            <p className='three-spaces'>chunkEmbeddings.Add(i, (chunks[i], embedding));</p>
                                            <p className='two-spaces'>{llavederecha}</p>
                                            <p className='one-space'>{llavederecha}</p>
                                            <br></br>
                                            <p className='one-space'><span className='grey'>// Save embeddings to cache</span></p>
                                            <p className='one-space'><span className='blue'>var</span> cache = <span className='blue'>new</span> EmbeddingsCache</p>
                                            <p className='one-space'>{llaveizquierda}</p>
                                            <p className='two-spaces'>EntityName = entity,</p>
                                            <p className='two-spaces'>Embeddings = chunkEmbeddings.ToDictionary(</p>
                                            <p className='three-spaces'>kvp <code className='white'>={mayor}</code> kvp.Key,</p>
                                            <p className='three-spaces'>kvp <code className='white'>={mayor}</code> <span className='blue'>new</span> CacheEntry</p>
                                            <p className='three-spaces'>{llaveizquierda}</p>
                                            <p className='four-spaces'>Text = kvp.Value.text,</p>
                                            <p className='four-spaces'>Embedding = kvp.Value.embedding</p>
                                            <p className='three-spaces'>{llavederecha}</p>
                                            <p className='two-spaces'>)</p>
                                            <p className='one-space'>{llavederecha};</p>
                                            <br></br>
                                            <p className='one-space'>File.WriteAllText(EMBEDDINGS_CACHE_PATH, JsonSerializer.Serialize(cache));</p>
                                            <p>{llavederecha}</p>
                                            <br></br>
                                            <p>Console.WriteLine(<span className='green'>"Embeddings created! ✓"</span>);</p>
                                        </div>
                                    </div>
                                </div>
                                <h3>Step 5: Chat with System Prompt and Relevant Context</h3>
                                <div className='step'>
                                    <p>
                                        This final step involves using the previously generated embeddings and sending messages to an Ollama-powered chat system.
                                        The chat system is designed to answer questions by first establishing a system prompt, then utilizing <span className='fw600'>embeddings</span> to provide context to the conversation.
                                        Here's how the chat works:
                                    </p>
                                    <p><span className='bold'>Sending the System Prompt</span></p>
                                    <p>
                                        The <span className='fw600'>system prompt</span> is sent to the chat first, defining the assistant's behavior and scope.
                                        It sets up the context and instructions for the assistant on how to respond to user queries. In this case, the system prompt includes:
                                    </p>
                                    <ul className='custom-bullets'>
                                        <li>
                                            <span>Data Analysis:</span> The assistant's role is to analyze Dataverse records, identify patterns, and provide insights.
                                        </li>
                                        <li>
                                            <span>Technical Support:</span> It helps with issues like plugin trace logs, flow runs, and custom entity analysis.
                                        </li>
                                        <li>
                                            <span>Response Guidelines:</span> The assistant is instructed to respond concisely, use technical terms appropriately, and format answers clearly.
                                        </li>
                                        <li>
                                            <span>Plugin Trace Logs:</span> Special handling instructions for analyzing log messages and exceptions.
                                        </li>
                                    </ul>
                                    <p>This prompt is used to configure the chat assistant to understand the context of the data it will analyze and the type of responses it should generate.</p>
                                    <div className='bash mt-10'>
                                        <div className='bash-header'>
                                            <label>bash</label>
                                            <Button onClick={() => this.onClickCopy(6, false)}>
                                                <Icon className={this.state.codeCopied == 6 ? 'check' : ''} name={this.state.codeCopied == 6 ? 'check' : 'copy outline'} />
                                                <p>{this.state.codeCopied == 6 ? ' Copied!' : 'Copy'}</p>
                                            </Button>
                                        </div>
                                        <div className='bash-body'>
                                            <p><span className='blue'>var</span> systemPrompt = File.ReadAllText(Path.Combine(AppDomain.CurrentDomain.BaseDirectory, <span className='green'>"Prompts"</span>, <span className='green'>"SystemPrompt.txt"</span>));</p>
                                            <br></br>
                                            <p><span className='blue'>await foreach</span> (<span className='blue'>var</span> token <span className='blue'>in</span> chat.SendAsAsync(OllamaSharp.Models.Chat.ChatRole.System, systemPrompt))</p>
                                            <p>{llaveizquierda}</p>
                                            <p className='one-space'><span className='grey'>// Optional: Show progress of system message</span></p>
                                            <p className='one-space'><span className='grey'>// You can log or display the token here if needed</span></p>
                                            <p>{llavederecha}</p>
                                        </div>
                                    </div>
                                    <p>
                                        Here, the system prompt is sent to Ollama for initial configuration. This ensures that the assistant is set up properly before responding to user queries.
                                    </p>
                                    <p><span className='bold'>Chat Interaction</span></p>
                                    <p>Once the system is set up, the user can ask questions about the records, and the assistant responds based on the relevant context.</p>
                                    <ul className='custom-bullets'>
                                        <li>
                                            <span>User Question:</span> The user types a question into the console.
                                        </li>
                                        <li>
                                            <span>Question Embedding:</span> The user's question is converted into an embedding using the same method used for the record summaries.
                                        </li>
                                        <li>
                                            <span>Finding Relevant Context:</span> The embeddings of the question are compared to the pre-generated embeddings of the records. The most relevant chunks are selected using <span>cosine similarity</span>.
                                        </li>
                                        <li>
                                            <span>Sending Context:</span> The assistant receives the relevant context (most relevant chunks) along with the user’s question to provide an accurate answer.
                                        </li>
                                    </ul>
                                    <div className='bash mt-10'>
                                        <div className='bash-header'>
                                            <label>bash</label>
                                            <Button onClick={() => this.onClickCopy(7, false)}>
                                                <Icon className={this.state.codeCopied == 7 ? 'check' : ''} name={this.state.codeCopied == 7 ? 'check' : 'copy outline'} />
                                                <p>{this.state.codeCopied == 7 ? ' Copied!' : 'Copy'}</p>
                                            </Button>
                                        </div>
                                        <div className='bash-body'>
                                            <p><span className='blue'>while</span> (<span className='blue'>true</span>)</p>
                                            <p>{llaveizquierda}</p>
                                            <p className='one-space'>Console.Write(<span className='green'>"\nAsk a question (or 'exit' to quit): "</span>);</p>
                                            <p className='one-space'><span className='blue'>var</span> message = Console.ReadLine();</p>
                                            <p className='one-space'><span className='blue'>if</span> (message?.ToLower() == <span className='green'>"exit"</span>) <span className='blue'>break</span>;</p>
                                            <br></br>
                                            <p className='one-space'><span className='blue'>var</span> similarityMethod = ConfigurationManager.AppSettings[<span className='green'>"SimilarityMethod"</span>];</p>
                                            <p className='one-space'>List{menor}(List{menor}<span className='orange'>string</span>{mayor} text, <span className='orange'>double</span> score){mayor} relevantChunks;</p>
                                            <br></br>
                                            <p className='one-space'><span className='blue'>if</span> (similarityMethod?.ToLower() == <span className='green'>"bm25"</span>)</p>
                                            <p className='one-space'>{llaveizquierda}</p>
                                            <p className='two-spaces'><span className='grey'>// Convert chunks for BM25 format</span></p>
                                            <p className='two-spaces'><span className='blue'>var</span> textChunks = chunkEmbeddings.ToDictionary{parentesisizquierdo}</p>
                                            <p className='three-spaces'>kvp <code className='white'>!=</code> kvp.Key,</p>
                                            <p className='three-spaces'>kvp <code className='white'>!=</code> kvp.Value.text</p>
                                            <p className='two-spaces'>{parentesisderecho};</p>
                                            <br></br>
                                            <p className='two-spaces'>relevantChunks = OllamaManager.FindMostRelevantChunksBM25(message, textChunks, topK: <span className='red'>3</span>);</p>
                                            <p className='one-space'>{llavederecha}</p>
                                            <p className='one-space'><span className='blue'>else</span> <span className='grey'>// Default to Cosine similarity</span></p>
                                            <p className='one-space'>{llaveizquierda}</p>
                                            <p className='two-spaces'><span className='blue'>var</span> questionEmbedding = <span className='blue'>await</span> embedOllama.GenerateEmbeddingAsync(message);</p>
                                            <p className='two-spaces'><span className='blue'>var</span> cosineChunks = OllamaManager.FindMostRelevantChunks(questionEmbedding.Vector.ToArray(), chunkEmbeddings, topK: <span className='red'>3</span>);</p>
                                            <br></br>
                                            <p className='two-spaces'><span className='grey'>// Convert to common format</span></p>
                                            <p className='two-spaces'>relevantChunks = cosineChunks.Select{parentesisizquierdo}c <code className='white'>!=</code></p>
                                            <p className='three-spaces'>(c.text, (<span className='orange'>double</span>)OllamaManager.CosineSimilarity(questionEmbedding.Vector.ToArray(), c.embedding))</p>
                                            <p className='two-spaces'>{parentesisderecho}.ToList();</p>
                                            <p className='one-space'>{llavederecha}</p>
                                            <br></br>
                                            <p className='one-space'><span className='grey'>// Construct the context from relevant chunks</span></p>
                                            <p className='one-space'><span className='blue'>var</span> context = <span className='orange'>string</span>.Join(<span className='green'>"\n"</span>, relevantChunks.SelectMany(c <code className='white'>!=</code> c.text));</p>
                                            <br></br>
                                            <p className='one-space'><span className='grey'>// Send the question and context to the chat</span></p>
                                            <p className='one-space'><span className='blue'>await foreach</span> (<span className='blue'>var</span> answerToken <span className='blue'>in</span> chat.SendAsync(<span className='green'>$"Context:\n{llaveizquierda}context{llavederecha}\n\nQuestion: {llaveizquierda}message{llavederecha}"</span>))</p>
                                            <p className='one-space'>{llaveizquierda}</p>
                                            <p className='two-spaces'>Console.Write(answerToken);</p>
                                            <p className='one-space'>{llavederecha}</p>
                                            <p>{llavederecha}</p>
                                        </div>
                                    </div>
                                    <p>This step ensures that the assistant provides answers based on relevant data from Dataverse, while adhering to the behavior set by the system prompt.</p>
                                </div>
                                <h3>Demo Video</h3>
                                <div className='step'>
                                    <p>To make the implementation clearer, here is a demonstration video showcasing the execution and results of the application:</p>
                                    <ReactPlayer
                                        url='../../dataverse-ollama.mp4'
                                        poster="../../portada.png"
                                        controls={true}
                                        width='100%'
                                        height='auto'
                                        style={{
                                            borderRadius: '6px',

                                            background: 'linear-gradient(145deg, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.1) 100%)'
                                        }}
                                        config={{
                                            file: {
                                                attributes: {
                                                    poster: '../../portada4.png'
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                <h3>Conclusion</h3>
                                <div className='step'>
                                    <p>
                                        Integrating Ollama to Microsoft Dataverse unlocks powerful insights by combining the flexibility of AI with the rich data in your Dataverse environment.
                                    </p>
                                    <p>
                                        By following this guide, you now have a framework for exploring Dataverse records interactively, diagnosing issues, and uncovering valuable trends.
                                        This integration showcases how AI can elevate the potential of existing platforms, making data more accessible and actionable for users.
                                    </p>
                                    <p>
                                        We encourage you to adapt and extend this solution to fit your specific use cases.
                                        Whether you're analyzing plugin logs, monitoring flow executions, or working with custom entities, this approach can provide a meaningful layer of intelligence to your Dataverse applications.
                                    </p>
                                </div>
                                <h3>Source Code</h3>
                                <div className='last-step'>
                                    <p>
                                        You can find the complete source code for this project on GitHub. Feel free to explore, clone, and modify it for your own use: <a href='https://github.com/coowise/OllamaDataverseEntityChatApp' target='_blank'>GitHub Repository: OllamaDataverseEntityChatApp</a>
                                    </p>
                                    <p>
                                        This repository includes all the files and configurations used in this tutorial, making it easy for you to replicate and extend the application. The code is well-structured and includes helpers for UI, Dataverse operations, and Ollama integration to simplify development and ensure modularity.
                                    </p>
                                </div>
                            </div>
                            <div className='footer'>
                                <span>AI Development</span>
                                <span>Dataverse</span>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

const mapStateToProps = (value) => {
    return {
        language: value.general.language,

        currentSection: value.general.currentSection,

        cookieUp: value.general.cookieUp
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OllamaDataverseImplementation);