import React, { Component } from 'react';
import Footer from '../Footer';
import { connect } from "react-redux";
import ReactGA4 from 'react-ga4';
import {
  changeCurrentSection,
  changeSubscribeBlogStates
} from "../../actions/generalActions";
import magnifyingGlass from '../images/magnifying_glass.svg';
import arrowRight from '../images/arrow-right.svg';
import ollama from '../images/ollama.png';
import react from '../images/react-card.jpeg';
import { Helmet } from 'react-helmet';
import { Snackbar } from '@material/react-snackbar';
import { Icon } from "semantic-ui-react";
import ollamaDataverse from '../images/ollama-dataverse.png';
import ollamaDataverseMilvus from '../images/ollama-dataverse-milvus.png';

const AIDEVELOPMENT = "AI Development";
const AIDEVELOPMENTDATAVERSE = "AI Development - Dataverse";

const articles = [
  {
    title: "Integrating Milvus: Enhancing Dataverse with Vector Search Capabilities",
    description: "Discover how to integrate Milvus, a powerful vector database, with Ollama and Dataverse for enhanced similarity search capabilities.",
    category: AIDEVELOPMENTDATAVERSE,
    image: ollamaDataverseMilvus,
    date: "2025-01-20",
    hash: "#milvus-dataverse-integration",
    stage: "STAGE 3"
  },
  {
    title: "Integrating Ollama to Microsoft Dataverse: Technical implementation",
    description: "Learn how to build an intelligent data analysis tool by combining Ollama's AI capabilities with Microsoft Dataverse data.",
    category: AIDEVELOPMENTDATAVERSE,
    image: ollamaDataverse,
    date: "2024-12-27",
    hash: "#ollama-with-dataverse-technical-implementation",
    stage: "STAGE 2"
  },
  {
    title: "Integrating Ollama to Microsoft Dataverse: An Overview",
    description: "Explore the concept of combining Ollama's local AI capabilities with Microsoft Dataverse, and discover the potential benefits of this integration.",
    category: AIDEVELOPMENTDATAVERSE,
    image: ollamaDataverse,
    date: "2024-12-23",
    hash: "#ollama-with-dataverse-overview",
    stage: "STAGE 1"
  },
  {
    title: "Bolt.new vs. V0",
    description: "Discover the key differences between Bolt.new and V0, two powerful tools for web development. Find which tool fits your development needs.",
    category: "Web Development",
    image: react,
    date: "2024-12-02",
    hash: "#bolt.new-vs-v0",
    stage: ""
  },
  {
    title: "Ollama + .NET Scraper",
    description: "Learn how to build a .NET-based web scraper and integrate it with Ollama for powerful content analysis.",
    category: AIDEVELOPMENT,
    image: ollama,
    date: "2024-11-19",
    hash: "#extract-and-analyze-web-content-using-ollama",
    stage: ""
  },
  {
    title: "How to build a chat app using ollama",
    description: "Combine the power of ollama with web technologies to create an intelligent chat application from scratch.",
    category: AIDEVELOPMENT,
    image: ollama,
    date: "2024-11-19",
    hash: "#build-chat-app-using-ollama",
    stage: ""
  },
  {
    title: "Getting started with Ollama in .NET",
    description: "Learn how to integrate ollama into your .NET console application for powerful language model capabilities.",
    category: AIDEVELOPMENT,
    image: ollama,
    date: "2024-11-19",
    hash: "#install-and-run-ollama-net",
    stage: ""
  }
];

class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      input: "",
      page: 1,
      newsletterModalFlag: false
    }

    this.changeSection = this.changeSection.bind(this);
    this.changeCategoryButton = this.changeCategoryButton.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.changePage = this.changePage.bind(this);
    this.changeArrowPage = this.changeArrowPage.bind(this);    
  }

  changeSection() {
    this.props.changeCurrentSection("Blog");
    if (this.props.cookieUp)
      ReactGA4.send({ hitType: "pageview", title: "Blog", page: '/Blog' });
  }

  // Cambia la categoría
  changeCategoryButton = (category) => {
    this.setState({
      input: category,
      page: 1, // Reinicia la paginación al filtrar por categoría
    });
  };

  // Maneja el input del filtro
  onChangeInput = (event) => {
    this.setState({
      input: event.target.value,
      page: 1, // Reinicia la paginación al cambiar el filtro
    });
  };

  // Cambia a una página específica
  changePage = (pageNumber) => {
    let element = document.getElementById("blog-global-screen");
    if (element != null)
      element.scrollIntoView({ behavior: "smooth" });

    this.setState({ page: pageNumber });
  };

  // Cambia la página con las flechas
  changeArrowPage = (direction) => {
    let element = document.getElementById("blog-global-screen");
    if (element != null)
      element.scrollIntoView({ behavior: "smooth" });

    const { page } = this.state;
    const maxPage = Math.ceil(
      this.getFilteredArticles().length / this.itemsPerPage()
    );

    if (direction === "left" && page > 1) {
      this.setState({ page: page - 1 });
    } else if (direction === "right" && page < maxPage) {
      this.setState({ page: page + 1 });
    }
  };

  // Obtiene los artículos filtrados
  getFilteredArticles = () => {
    const { input } = this.state;
    return articles.filter(
      (article) =>
        article.title.toLowerCase().includes(input.toLowerCase()) ||
        article.description.toLowerCase().includes(input.toLowerCase()) ||
        article.category.toLowerCase().includes(input.toLowerCase())
    );
  };

  // Calcula cuántos ítems mostrar por página
  itemsPerPage = () => {
    return window.innerWidth <= 767 ? 3 : articles.length; // 3 en móvil, todos en escritorio
  };

  // Calcula los artículos paginados
  getPaginatedArticles = () => {
    const filteredArticles = this.getFilteredArticles();
    const { page } = this.state;
    const itemsPerPage = this.itemsPerPage();

    return window.innerWidth <= 767
      ? filteredArticles.slice((page - 1) * itemsPerPage, page * itemsPerPage)
      : filteredArticles;
  };

  changeNewsletterModalFlag() {
    let newsletterModalFlag = JSON.parse(window.sessionStorage.getItem("newsletterModalFlag"));
    window.sessionStorage.setItem("newsletterModalFlag", !newsletterModalFlag);
    this.setState({ newsletterModalFlag: !this.state.newsletterModalFlag });
  }

  render() {
    const paginatedArticles = this.getPaginatedArticles();
    const filteredArticles = this.getFilteredArticles();
    const maxPage = Math.ceil(filteredArticles.length / this.itemsPerPage());

    return (
      <div id='blog-global-screen' className='blog-global-screen' onMouseOver={this.props.currentSection != "Blog" ? this.changeSection : () => { }}>
        <Helmet>
          <title>Blog - AI and Web Development Articles</title>
          <meta name="description" content="Explore articles about AI Development, Web Development, and practical tutorials on implementing Ollama, React, and modern web technologies." />
          <meta name="keywords" content="AI Development, Web Development, Ollama, React, Chat Applications, Tutorials, Dataverse" />

          {/* OpenGraph tags for social sharing */}
          <meta property="og:title" content="Blog - AI and Web Development Articles" />
          <meta property="og:description" content="Explore articles about AI Development, Web Development, and practical tutorials on implementing Ollama, React, and modern web technologies." />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={ollama} />

          {/* Additional meta tags */}
          <link rel="canonical" href={window.location.origin + "/blog"} />
        </Helmet>
        <section class="blog-section">
          <div className='blog-container'>
            <div className='blog-header'>
              <div className='blog-search-bar-container'>
                <input type="text" placeholder="Search articles..." className="blog-search-bar" onChange={this.onChangeInput} value={this.state.input}></input>
                <img className="magnifying-glass" src={magnifyingGlass}></img>
              </div>
              <div className='blog-category-buttons-container'>
                <button className='category-button' onClick={() => this.changeCategoryButton("AI Development")}>AI Development</button>
                <button className='category-button' onClick={() => this.changeCategoryButton("Web Development")}>Web Development</button>
                <button className='category-button' onClick={() => this.changeCategoryButton("Dataverse")}>Dataverse</button>
              </div>
            </div>
            <div className='blog-cards-section'>
              <div className='blog-cards-container'>
              {paginatedArticles.map((article, index) => (
                <div className="blog-card" key={index}>
                  <img className="thumbnails" src={article.image} />
                  <div className="blog-card-body-header">
                    <label>{article.category}</label>
                    {article.stage != "" && <label className='stage'>{article.stage}</label>}
                  </div>
                  <h3>{article.title}</h3>
                  <p className="card-paragraph">{article.description}</p>
                  <div className="blog-card-footer">
                    <label>{article.date}</label>
                    <a href={`${window.location.origin}/article${article.hash}`}>
                      <div className="footer-container">
                        <label>Read more</label>
                        <img src={arrowRight} />
                      </div>
                    </a>
                  </div>
                </div>
              ))}
              </div>
            </div>
            {window.innerWidth <= 767 && (
          <div className="blog-footer">
            {
               maxPage > 1 && 
                <Icon
                  onClick={() => this.changeArrowPage("left")}
                  name={"angle left"}
                />
            }
            {Array.from({ length: maxPage }, (_, index) => (
              <button
                key={index}
                onClick={() => this.changePage(index + 1)}
                className={this.state.page === index + 1 ? "selected" : ""}
              >
                {index + 1}
              </button>
            ))}
            {
              maxPage > 1 && 
              <Icon
                onClick={() => this.changeArrowPage("right")}
                name={"angle right"}
              />
            }
          </div>
        )}
          </div>
        </section>
        <Footer
          language={this.props.language}
          currentSection={this.props.currentSection}
          changeCurrentSection={this.props.changeCurrentSection}
          cookieUp={this.props.cookieUp}
        />
        {
          this.props.subscribeBlogError !== null || this.props.fetchedSubscribeBlog
            ?
            <Snackbar
              className={this.props.fetchedSubscribeBlog ? "toastSuccess" : "errorToast"}
              message=
              {
                this.props.fetchedSubscribeBlog
                  ? this.props.language == "ES"
                    ? "Se ha suscrito con exito."
                    : "You have successfully subscribed"
                  : this.props.language == "ES"
                    ? "Ha ocurrido un error. Intente nuevamente."
                    : "An error has occurred. Try again."
              }
              actionText="X"
              onClose=
              {
                this.props.fetchedSubscribeBlog
                  ? () => this.props.changeSubscribeBlogStates()
                  : () => { }
              }
            />
            : null
        }
      </div>
    );
  }
}

const mapStateToProps = (value) => {
  return {
    language: value.general.language,

    currentSection: value.general.currentSection,

    cookieUp: value.general.cookieUp,

    fetchedSubscribeBlog: value.general.fetchedSubscribeBlog,
    subscribeBlogError: value.general.subscribeBlogError
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection)),
    changeSubscribeBlogStates: () => dispatch(changeSubscribeBlogStates())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);