import React, { Component } from 'react';
import { Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import ReactGA4 from 'react-ga4';
import {
  changeCurrentSection,
} from "../../../actions/generalActions";
import arrowLeft from '../../images/arrow-left.svg';
import calendar from '../../images/calendar.svg';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';

const texts = [
  "dotnet --version",
  "brew install ollama",
  `dotnet new console -n OllamaConsoleApp
cd OllamaConsoleApp`,
  `using System.Diagnostics;

class Program
{
    static void Main(string[] args)
    {
        var process = new Process
        {
            StartInfo = new ProcessStartInfo
            {
                FileName = "ollama",
                Arguments = "run llama3 'What is artificial intelligence?'",
                RedirectStandardOutput = true,
                UseShellExecute = false,
                CreateNoWindow = true
            }
        };

        process.Start();
        string result = process.StandardOutput.ReadToEnd();
        process.WaitForExit();

        Console.WriteLine(result);
    }
}
`,
  "dotnet run",
  "dotnet add package OllamaSharp",
  "ollama serve",
  `using OllamaSharp;
using System;
using System.Diagnostics;
using System.Threading.Tasks;

class Program
{
    static async Task Main(string[] args)
    {
        var ollamaApiClient = new OllamaApiClient("http://localhost:[replace with the port configured]") { SelectedModel = "llama3" };
        var ollamaChat = new Chat(ollamaApiClient);

        var response = await ollamaChat.SendAsync("What is artificial intelligence?");

        Console.WriteLine(response);
    }
}`,
"ollama pull llama3",
];

const delay = ms => new Promise(res => setTimeout(res, ms));

const llaveizquierda = "{";
const llavederecha = "}";

class GettingStartedWithOllama extends Component {
  constructor(props) {
    super(props);

    this.state = {
      codeCopied: 0
    }

    this.changeSection = this.changeSection.bind(this);
    this.onClickCopy = this.onClickCopy.bind(this);
  }

  changeSection() {
    this.props.changeCurrentSection("Blog");
    if (this.props.cookieUp)
      ReactGA4.send({ hitType: "pageview", title: "Blog", page: '/Blog' });
  }

  async onClickCopy(codeCopied, isJson) {
    if (isJson)
      navigator.clipboard.writeText(JSON.stringify(texts[codeCopied - 1], null, 2));
    else
      navigator.clipboard.writeText(texts[codeCopied - 1]);

    this.setState({ codeCopied: codeCopied });

    await delay(2000);

    this.setState({ codeCopied: 0 });
  }

  render() {
    return (
        <>
            <Helmet>
                <title>Getting started with Ollama in .NET</title>
                <meta name="description" content="Learn how to integrate Ollama, a powerful NLP tool, into a .NET console application. Step-by-step guide covering installation, configuration, and implementation." />
                <meta name="keywords" content="ollama, .NET, C#, AI, NLP, console application, language model, artificial intelligence" />
                <meta property="og:title" content="Getting started with Ollama in .NET" />
                <meta property="og:description" content="Learn how to integrate Ollama, a powerful NLP tool, into a .NET console application. Step-by-step guide covering installation, configuration, and implementation." />
                <meta property="og:type" content="article" />
                <meta property="article:published_time" content="2024-11-19" />
            </Helmet>
            <section class="article-section">
                <div className='article-container'>
                    <div className='article-header'>
                        <a href='/blog'>
                            <div className='back-button-container'>
                                <img src={arrowLeft} />
                                <label>Back to Articles</label>
                            </div>
                        </a>
                    </div>
                    <div className='article'>
                        <h1>Getting started with Ollama in .NET</h1>
                        <div className='author-date-container'>
                            <div className='general-container'>
                                <img src={calendar} />
                                <label>November 19, 2024</label>
                            </div>
                        </div>
                        <div className='article-div'>
                            <p>
                                In this article, we'll walk you through how to integrate Ollama, a powerful natural language processing (NLP) tool, into a .NET console application.
                                You’ll learn how to install Ollama, configure it, and use it to implement advanced language model capabilities.
                            </p>
                            <h2>Prerequisites</h2>
                            <div className='step'>
                                <p className='mb-05'>
                                    Before you begin, make sure you have the following installed:
                                </p>
                                <ul class="custom-bullets">
                                    <li>.NET SDK (version 8.0)</li>
                                    <li>Visual Studio or any editor of your choice</li>
                                    <li>Ollama installed on your machine (detailed instructions below)</li>
                                </ul>
                                <p>You can use the following command to verify the installed version of .NET.</p>
                                <div className='bash'>
                                    <div className='bash-header'>
                                        <label>bash</label>
                                        <Button onClick={() => this.onClickCopy(1, false)}>
                                            <Icon className={this.state.codeCopied == 1 ? 'check' : ''} name={this.state.codeCopied == 1 ? 'check' : 'copy outline'} />
                                            <p>{this.state.codeCopied == 1 ? ' Copied!' : 'Copy'}</p>
                                        </Button>
                                    </div>
                                    <div className='bash-body'>
                                        <p>dotnet --version</p>
                                    </div>
                                </div>
                            </div>
                            <h3>Step 1: Install Ollama</h3>
                            <div className='step'>
                                <p>Ollama can be easily installed from the command line. Depending on your operating system, you can use the following commands:</p>
                                <h4>On macOS or Linux:</h4>
                                <div className='bash mt-10'>
                                    <div className='bash-header'>
                                        <label>bash</label>
                                        <Button onClick={() => this.onClickCopy(2, false)}>
                                            <Icon className={this.state.codeCopied == 2 ? 'check' : ''} name={this.state.codeCopied == 2 ? 'check' : 'copy outline'} />
                                            <p>{this.state.codeCopied == 2 ? ' Copied!' : 'Copy'}</p>
                                        </Button>
                                    </div>
                                    <div className='bash-body'>
                                        <p>brew install ollama</p>
                                    </div>
                                </div>
                                <h4>On Windows:</h4>
                                <ol class="custom-bullets numbers">
                                    <li>Go to the <a href='https://ollama.com/' target='_blank'>Ollama page</a> and download the installer.</li>
                                    <li>Follow the installation instructions.</li>
                                </ol>
                                <p>Once Ollama is installed, you need to pull a language model. For example:</p>
                                <div className='bash mt-10'>
                                    <div className='bash-header'>
                                        <label>bash</label>
                                        <Button onClick={() => this.onClickCopy(9, false)}>
                                            <Icon className={this.state.codeCopied == 9 ? 'check' : ''} name={this.state.codeCopied == 9 ? 'check' : 'copy outline'} />
                                            <p>{this.state.codeCopied == 9 ? ' Copied!' : 'Copy'}</p>
                                        </Button>
                                    </div>
                                    <div className='bash-body'>
                                        <p>ollama pull llama3</p>
                                    </div>
                                </div>
                                <p>This command downloads the specified model (in this case, llama3) to your local environment, making it ready for use.</p>
                            </div>
                            <h3>Step 2: Create a New Console Application in .NET</h3>
                            <div className='step'>
                                <p>First, create a new console project in .NET by running the following in your terminal:</p>
                                <div className='bash'>
                                    <div className='bash-header'>
                                        <label>bash</label>
                                        <Button onClick={() => this.onClickCopy(3, false)}>
                                            <Icon className={this.state.codeCopied == 3 ? 'check' : ''} name={this.state.codeCopied == 3 ? 'check' : 'copy outline'} />
                                            <p>{this.state.codeCopied == 3 ? ' Copied!' : 'Copy'}</p>
                                        </Button>
                                    </div>
                                    <div className='bash-body'>
                                        <p>dotnet new console -n OllamaConsoleApp</p>
                                        <p><span className='orange'>cd</span> OllamaConsoleApp</p>
                                    </div>
                                </div>
                                <p>This command generates a basic project structure. In the <span>Program.cs</span> file, you’ll see a default <span>Main()</span> that we’ll use shortly.</p>
                            </div>
                            <h3>Step 3: Set Up Ollama Usage</h3>
                            <div className='step'>
                                <p>
                                    Now that you have Ollama installed and a .NET project created, the next step is to integrate Ollama with .NET. 
                                    Below are two alternative approaches to achieve this:
                                </p>
                                <p><span className="bold">Option 1: Using the OllamaSharp Package</span></p>
                                <p>Install the <span>OllamaSharp</span> NuGet package in your project:</p>
                                <div className='bash'>
                                    <div className='bash-header'>
                                        <label>bash</label>
                                        <Button onClick={() => this.onClickCopy(6, false)}>
                                            <Icon className={this.state.codeCopied == 6 ? 'check' : ''} name={this.state.codeCopied == 6 ? 'check' : 'copy outline'} />
                                            <p>{this.state.codeCopied == 6 ? ' Copied!' : 'Copy'}</p>
                                        </Button>
                                    </div>
                                    <div className='bash-body'>
                                        <p>dotnet add package OllamaSharp</p>
                                    </div>
                                </div>
                                <p>Start the Ollama server by running the following command in your terminal:</p>
                                <div className='bash'>
                                    <div className='bash-header'>
                                        <label>bash</label>
                                        <Button onClick={() => this.onClickCopy(7, false)}>
                                            <Icon className={this.state.codeCopied == 7 ? 'check' : ''} name={this.state.codeCopied == 7 ? 'check' : 'copy outline'} />
                                            <p>{this.state.codeCopied == 7 ? ' Copied!' : 'Copy'}</p>
                                        </Button>
                                    </div>
                                    <div className='bash-body'>
                                        <p>ollama serve</p>
                                    </div>
                                </div>
                                <p>Update your <span>Program.cs</span> file with the following code to use <span>OllamaSharp</span>:</p>
                                <div className='bash'>
                                    <div className='bash-header'>
                                        <label>csharp</label>
                                        <Button onClick={() => this.onClickCopy(8, false)}>
                                            <Icon className={this.state.codeCopied == 8 ? 'check' : ''} name={this.state.codeCopied == 8 ? 'check' : 'copy outline'} />
                                            <p>{this.state.codeCopied == 8 ? ' Copied!' : 'Copy'}</p>
                                        </Button>
                                    </div>
                                    <div className='bash-body'>
                                        <p><span className='blue'>using</span> OllamaSharp;</p>
                                        <p><span className='blue'>using</span> System;</p>
                                        <p><span className='blue'>using</span> System.Diagnostics;</p>
                                        <p><span className='blue'>using</span> System.Threading.Tasks;</p>
                                        <br></br>
                                        <p><span className='blue'>class</span> <span className='red'>Program</span></p>
                                        <p>{llaveizquierda}</p>
                                        <p className='one-space'><span className='blue'>static async</span> Task <span className='red'>Main</span>(<span className='orange'>string</span>[] args)</p>
                                        <p className='one-space'>{llaveizquierda}</p>
                                        <p className='two-spaces'><span className='blue'>var</span> ollamaApiClient = <span className='blue'>new</span> OllamaApiClient(<span className="green">"http://localhost:[replace with the port configured]"</span>) {llaveizquierda} SelectedModel = <span className="green">"llama3"</span> {llavederecha};</p>
                                        <p className='two-spaces'><span className='blue'>var</span> ollamaChat = <span className='blue'>new</span> Chat(ollamaApiClient);</p>
                                        <br></br>
                                        <p className='two-spaces'><span className='blue'>var</span> response = <span className='blue'>await</span> ollamaChat.SendAsync(<span className="green">"What is artificial intelligence?"</span>);</p>
                                        <br></br>
                                        <p className='two-spaces'>Console.WriteLine(response);</p>
                                        <p className='one-space'>{llavederecha}</p>
                                        <p>{llavederecha}</p>
                                    </div>
                                </div>
                                <p>This approach uses the OllamaSharp library to interact with the Ollama server, providing a more streamlined integration.</p>
                                <p><span className="bold">Option 2: Using the Process Class</span></p>
                                <p>
                                    If you prefer not to use a library, you can run Ollama commands directly from your console app using the Process class in .NET. 
                                    Add the following code to your <span>Program.cs</span> file:
                                </p>
                                <div className='bash'>
                                    <div className='bash-header'>
                                        <label>csharp</label>
                                        <Button onClick={() => this.onClickCopy(4, false)}>
                                            <Icon className={this.state.codeCopied == 4 ? 'check' : ''} name={this.state.codeCopied == 4 ? 'check' : 'copy outline'} />
                                            <p>{this.state.codeCopied == 4 ? ' Copied!' : 'Copy'}</p>
                                        </Button>
                                    </div>
                                    <div className='bash-body'>
                                        <p><span className='blue'>using</span> System.Diagnostics;</p>
                                        <br></br>
                                        <p><span className='blue'>class</span> <span className='red'>Program</span></p>
                                        <p>{llaveizquierda}</p>
                                        <p className='one-space'><span className='blue'>static void</span> <span className='red'>Main</span>(<span className='orange'>string</span>[] args)</p>
                                        <p className='one-space'>{llaveizquierda}</p>
                                        <p className='two-spaces'><span className='blue'>var</span> process = <span className='blue'>new</span> Process</p>
                                        <p className='two-spaces'>{llaveizquierda}</p>
                                        <p className='three-spaces'>StartInfo = <span className='blue'>new</span> ProcessStartInfo</p>
                                        <p className='three-spaces'>{llaveizquierda}</p>
                                        <p className='four-spaces'>FileName = <span className='green'>"ollama"</span>,</p>
                                        <p className='four-spaces'>Arguments = <span className='green'>"run llama3 'What is artificial intelligence?'"</span>,</p>
                                        <p className='four-spaces'>RedirectStandardOutput = <span className='blue'>true</span>,</p>
                                        <p className='four-spaces'>UseShellExecute = <span className='blue'>false</span>,</p>
                                        <p className='four-spaces'>CreateNoWindow = <span className='blue'>true</span></p>
                                        <p className='three-spaces'>{llavederecha}</p>
                                        <p className='two-spaces'>{llavederecha};</p>
                                        <br></br>
                                        <p className='two-spaces'>process.Start();</p>
                                        <p className='two-spaces'><span className='orange'>string</span> result = process.StandardOutput.ReadToEnd();</p>
                                        <p className='two-spaces'>process.WaitForExit();</p>
                                        <p className='two-spaces'>Console.WriteLine(result);</p>
                                        <p className='one-space'>{llavederecha}</p>
                                        <p>{llavederecha}</p>
                                    </div>
                                </div>
                                <p>This code runs a language model in Ollama and displays the result in the console.</p>
                            </div>
                            <h3>Step 4: Test Your Application</h3>
                            <div className='step'>
                                <p>Now that the code is set up, you can run your application to see the results. In the terminal, within the project directory, run:</p>
                                <div className='bash'>
                                    <div className='bash-header'>
                                        <label>bash</label>
                                        <Button onClick={() => this.onClickCopy(5, false)}>
                                            <Icon className={this.state.codeCopied == 5 ? 'check' : ''} name={this.state.codeCopied == 5 ? 'check' : 'copy outline'} />
                                            <p>{this.state.codeCopied == 5 ? ' Copied!' : 'Copy'}</p>
                                        </Button>
                                    </div>
                                    <div className='bash-body'>
                                        <p>dotnet run</p>
                                    </div>
                                </div>
                                <p>This should execute the Ollama model and display a response in the console.</p>
                            </div>
                            <h3>Demo Video</h3>
                            <div className='step'>
                                <p>To make the implementation clearer, here is a demonstration video showcasing the execution and results of the application:</p>
                                <ReactPlayer
                                    url='../../getting-started-demo.mp4'
                                    poster="../../portada.png"
                                    controls={true}
                                    width='100%'
                                    height='auto'
                                    style={{
                                        borderRadius: '6px',

                                        background: 'linear-gradient(145deg, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.1) 100%)'
                                    }}
                                    config={{
                                        file: {
                                            attributes: {
                                                poster: '../../portada.png'
                                            }
                                        }
                                    }}
                                />
                            </div>
                            <h3>Conclusion</h3>
                            <div className='step'>
                                <p>
                                    In this article, we covered the essential steps to integrate Ollama into a .NET console application, from installation to running the model.
                                    You can now experiment with different commands and language models within your .NET project to add advanced capabilities to your applications.
                                </p>
                            </div>
                            <h3>Source Code</h3>
                            <div className='last-step'>
                                <p>
                                    You can find the complete source code for this project on GitHub. Feel free to explore, clone, and modify it for your own use: <a href='https://github.com/coowise/OllamaConsoleApp' target='_blank'>GitHub Repository: OllamaConsoleApp</a>
                                </p>
                                <p>
                                    This repository includes all the files and configurations used in this tutorial, making it easy for you to replicate and extend the application.
                                </p>
                            </div>
                        </div>
                        <div className='footer'>
                            <span>AI Development</span>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
  }
}

const mapStateToProps = (value) => {
  return {
    language: value.general.language,

    currentSection: value.general.currentSection,

    cookieUp: value.general.cookieUp
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GettingStartedWithOllama);