import React, { Component } from 'react';
import { Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import ReactGA4 from 'react-ga4';
import {
    changeCurrentSection,
} from "../../../actions/generalActions";
import arrowLeft from '../../images/arrow-left.svg';
import calendar from '../../images/calendar.svg';
import { Helmet } from 'react-helmet';

class OllamaDataverseOverview extends Component {
    constructor(props) {
        super(props);

        this.changeSection = this.changeSection.bind(this);
    }

    changeSection() {
        this.props.changeCurrentSection("Blog");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "Blog", page: '/Blog' });
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Integrating Ollama to Microsoft Dataverse: An Overview</title>
                    <meta name="description" content="Explore the concept of combining Ollama's local AI capabilities with Microsoft Dataverse, and discover the potential benefits of this integration." />
                    <meta name="keywords" content="ollama, .NET, C#, AI, NLP, console application, language model, artificial intelligence, dataverse" />
                    <meta property="og:title" content="Integrating Ollama to Microsoft Dataverse: An Overview" />
                    <meta property="og:description" content="Explore the concept of combining Ollama's local AI capabilities with Microsoft Dataverse, and discover the potential benefits of this integration." />
                    <meta property="og:type" content="article" />
                    <meta property="article:published_time" content="2024-12-23" />
                </Helmet>
                <section class="article-section">
                    <div className='article-container'>
                        <div className='article-header'>
                            <a href='/blog'>
                                <div className='back-button-container'>
                                    <img src={arrowLeft} />
                                    <label>Back to Articles</label>
                                </div>
                            </a>
                        </div>
                        <div className='article'>
                            <h1>Integrating Ollama to Microsoft Dataverse: An Overview</h1>
                            <div className='author-date-container'>
                                <div className='general-container'>
                                    <img src={calendar} />
                                    <label>December 23, 2024</label>
                                </div>
                            </div>
                            <div className='article-div'>
                                <p>
                                    Combining robust data platforms like Microsoft Dataverse with advanced AI capabilities offers immense potential to revolutionize how we interact with data.
                                    This article introduces a proof of concept that uses a chat interface to query Dataverse data via natural language, processed by llama’s AI model.
                                </p>
                                <p>
                                    The goal is to evaluate the quality of this exploratory integration, acknowledging its limitations and highlighting its utility for specific cases.
                                    It is particularly effective for entities with records that do not change, such as Flow Runs and Plugin Trace Logs.
                                </p>
                                <h3>Solution Concept</h3>
                                <div className='step pr-10'>
                                    <p className='mb-05'>
                                        The proposed chat solution follows a logical flow designed to maximize value from Dataverse data and leverage semantic analysis capabilities provided by Ollama.
                                        The key steps include:
                                    </p>
                                    <div className='two-column-step'>
                                        <div>
                                            <ol className='custom-bullets numbers'>
                                                <li className='two'>
                                                    <p className='list-title'>Connecting to Dataverse</p>
                                                    <p>Establish a secure connection to the Dataverse environment, configuring authentication and selecting the desired environment. This allows access to entities and records for analysis.</p>
                                                </li>
                                                <li className='two'>
                                                    <p className='list-title'>Retrieving Entity Metadata</p>
                                                    <p>Extract structural information about available entities, including their fields and relationships. This is essential for understanding how data can be queried and processed.</p>
                                                </li>
                                                <li className='two'>
                                                    <p className='list-title'>Building and Executing Queries</p>
                                                    <p>Dynamically generate queries based on user input to retrieve relevant records from Dataverse.</p>
                                                </li>
                                                <li className='two'>
                                                    <p className='list-title'>Embedding Records using Ollama</p>
                                                    <p>Convert retrieved records into vectors through an embedding process, enabling llama's AI model to semantically analyze the data and extract relevant insights.</p>
                                                </li>
                                                <li className='two'>
                                                    <p className='list-title'>Prioritizing Results with Cosine Similarity</p>
                                                    <p>Use cosine similarity to rank embeddings, ensuring the most relevant results are highlighted for user queries.</p>
                                                </li>
                                                <li className='two'>
                                                    <p className='list-title'>Interacting Through Chat</p>
                                                    <p>The chat interface allows users to interact with data intuitively. Ollama responds to natural language queries based on the embeddings of Dataverse-stored data.</p>
                                                </li>
                                            </ol>
                                        </div>
                                        <div className='step-line'>
                                            <div className='relative'>
                                                <div className='gradient'>
                                                    <div className='step-card'>
                                                        <div className='step-card-container'>
                                                            <Icon className={'chain'} />
                                                            <h3>Connect to Dataverse</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='line'></div>
                                            </div>
                                            <div className='relative'>
                                                <div className='gradient'>
                                                    <div className='step-card'>
                                                        <div className='step-card-container'>
                                                            <Icon className={'chain'} />
                                                            <h3>Retrieve entity metadata</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='line'></div>
                                            </div>
                                            <div className='relative'>
                                                <div className='gradient'>
                                                    <div className='step-card'>
                                                        <div className='step-card-container'>
                                                            <Icon className={'chain'} />
                                                            <h3>Building and executing query</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='line'></div>
                                            </div>
                                            <div className='relative'>
                                                <div className='gradient'>
                                                    <div className='step-card'>
                                                        <div className='step-card-container'>
                                                            <Icon className={'chain'} />
                                                            <h3>Embedding records using Ollama</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='line'></div>
                                            </div>
                                            <div className='relative'>
                                                <div className='gradient'>
                                                    <div className='step-card'>
                                                        <div className='step-card-container'>
                                                            <Icon className={'chain'} />
                                                            <h3>Prioritizing Results with Cosine Similarity</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='line'></div>
                                            </div>
                                            <div className='relative'>
                                                <div className='gradient'>
                                                    <div className='step-card'>
                                                        <div className='step-card-container'>
                                                            <Icon className={'chain'} />
                                                            <h3>Chat your data</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3>Technical Aspects</h3>
                                <div className='step'>
                                    <p><span className='bold'>Embedding and Information Retrieval</span></p>
                                    <p>
                                        Embeddings transform text records into numerical vector representations, making it easier to compare their similarities.
                                        This solution uses the cosine similarity algorithm to identify the most relevant records for user queries.
                                        This ensures that chat responses align closely with user queries, delivering relevant results efficiently.
                                    </p>
                                    <p><span className='bold'>Ideal Data Characteristics</span></p>
                                    <p>
                                        This solution is especially suited for entities like Flow Runs and Plugin Trace Logs,
                                        whose records are static and do not update after creation. This eliminates the need for frequent recalculations of embeddings,
                                        enhancing overall performance.
                                    </p>
                                </div>
                                <h3>Limitations and Configurations</h3>
                                <div className='step'>
                                    <p><span className='bold'>Hardware and Performance</span></p>
                                    <p>
                                        The performance of this solution heavily depends on the hardware used.
                                        More powerful llama models offer better responses but require greater computational capacity.
                                        It’s recommended to configure model parameters according to available resources for optimal balance.
                                    </p>
                                    <p><span className='bold'>Data Size</span></p>
                                    <p>
                                        The current design is suitable for small datasets due to language model context limitations.
                                        For larger datasets, it would be necessary to split records into smaller chunks and adjust the flow to avoid token limit-related issues.
                                    </p>
                                </div>
                                <h3>Exploration and Conclusions</h3>
                                <div className='step'>
                                    <p>
                                        This proof of concept aims to explore the quality achievable by integrating Microsoft Dataverse with AI models like Ollama.
                                        Using an embedding and semantic analysis approach, the solution enables intuitive and efficient data interaction,
                                        though it has inherent limitations related to data size and hardware capacity.
                                    </p>
                                    <p>
                                        We invite you to experiment with this solution and share your observations.
                                        Discover how data and AI can work together to transform how we interact with information!
                                    </p>
                                </div>
                                <h3>From Concept to Code</h3>
                                <div className='last-step'>
                                    <p>
                                        For a detailed walkthrough of how to implement this integration, check out our follow-up article: <a href='/article#ollama-with-dataverse-technical-implementation' target='_blank'>Integrating Ollama to Microsoft Dataverse: Technical implementation</a>.
                                        This step-by-step guide demonstrates how to turn the concepts discussed here into a working solution, providing insights into embedding, query execution, and chat integration with Dataverse data.
                                    </p>
                                </div>
                            </div>
                            <div className='footer'>
                                <span>AI Development</span>
                                <span>Dataverse</span>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

const mapStateToProps = (value) => {
    return {
        language: value.general.language,

        currentSection: value.general.currentSection,

        cookieUp: value.general.cookieUp
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OllamaDataverseOverview);