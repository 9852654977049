import React, { useState, useEffect } from 'react';
import Spinner from './Spinner';
import arrowLeft from './images/arrow-left.svg';

const ChatBubble = () => {
  const REACT_APP_BOOK_A_CALL_URL = process.env.REACT_APP_BOOK_A_CALL_URL;

  const [isOpen, setIsOpen] = useState(false);
  const [view, setView] = useState('options'); // 'options', 'calendar', or 'chat'
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [isBooking, setIsBooking] = useState(false);
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [bookingFailed, setBookingFailed] = useState(false);
  const [bookingFailedError, setBookingFailedError] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [userEvent, setUserEvent] = useState('');

  const generateAvailableSlots = (events) => {
    const slots = [];
    const today = new Date();
    const currentHour = today.getHours();

    // Generate slots for the next 7 days
    for (let i = 0; i < 7; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      date.setHours(9, 0, 0, 0); // Start at 9 AM

      const dayEvents = events.filter(event => {
        const eventDate = new Date(event.start.dateTime);
        return eventDate.toDateString() === date.toDateString();
      });

      // Generate 1-hour slots between 9 AM and 5 PM
      for (let hour = 9; hour < 17; hour++) {
        // Skip times that have already passed today
        if (i === 0 && hour <= currentHour) continue;

        const slotStart = new Date(date);
        slotStart.setHours(hour);
        const slotEnd = new Date(date);
        slotEnd.setHours(hour + 1);

        // Check if slot conflicts with any existing event
        const hasConflict = dayEvents.some(event => {
          const eventStart = new Date(event.start.dateTime); // Esto te da la hora en UTC
          const eventEnd = new Date(event.end.dateTime);     // Esto te da la hora en UTC

          // Ahora vamos a ajustar la hora para convertirla a la hora local
          // Obtener el desfase horario entre UTC y tu zona horaria local (en milisegundos)
          const localOffset = eventStart.getTimezoneOffset() * 60000; // El offset es negativo si la zona local es adelante de UTC

          // Crear nuevas fechas ajustadas a la zona horaria local
          const localEventStart = new Date(eventStart.getTime() - localOffset);
          const localEventEnd = new Date(eventEnd.getTime() - localOffset);
          return (slotStart >= localEventStart && slotStart < localEventEnd) ||
            (slotEnd > localEventStart && slotEnd <= localEventEnd);
        });

        if (!hasConflict) {
          slots.push({
            start: slotStart,
            end: slotEnd
          });
        }
      }
    }
    return slots;
  };

  const fetchCalendarEvents = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(REACT_APP_BOOK_A_CALL_URL + '/api/calendar/events');
      const { value: events } = await response.json();
      setAvailableSlots(generateAvailableSlots(events));
      setEvents(events);
    } catch (error) {
      console.error('Error fetching calendar events:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (view === 'calendar') {
      fetchCalendarEvents();
    }
  }, [view]);

  const handleBookCall = () => {
    setIsLoading(true);
    setView('calendar');
  };

  const handleChat = () => {
    setView('chat');
  };

  const handleBack = () => {
    setView('options');
    setEvents([]);
    setUserEvent('');
    setAvailableSlots([]);
    setSelectedSlot(null);
    setUserEmail('');
    setBookingFailed(false);
    setBookingFailedError('');
  };

  const handleSlotSelect = (slot) => {
    setSelectedSlot(slot);
  };

  const handleBookSlot = async () => {
    let passed = checkEmail();

    if (passed) {
      if (!selectedSlot) return;

      setIsBooking(true);
      try {
        const startTime = new Date(selectedSlot.start);
        const endTime = new Date(selectedSlot.end);

        const requestBody = {
          startTime: startTime.toISOString(),
          endTime: endTime.toISOString(),
          subject: `Coowise x ${userEmail}`,
          attendeeEmails: [userEmail, 'contact@coowise.com'],
          ccEmails: ['ntiseira@coowise.com', 'jonathan@coowise.com']
        };

        console.log('Sending calendar event request:', JSON.stringify(requestBody, null, 2));

        const response = await fetch(REACT_APP_BOOK_A_CALL_URL + '/api/calendar/events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          const responseData = await response.json();

          if (responseData.error != undefined && responseData.error === 'An event already exists with the same attendee.' && responseData.event != undefined && responseData.event != null) 
          {
            // Convertir la fecha y ajustar a la zona horaria local
            const start = new Date(responseData.event.start.dateTime); // Hora en UTC
            const localOffset = start.getTimezoneOffset() * 60000; // El offset es negativo si la zona local es adelante de UTC

            // Crear nuevas fechas ajustadas a la zona horaria local
            const localEventStart = new Date(start.getTime() - localOffset);

            // Formatear la hora
            const timeString = localEventStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

            // Obtener abreviatura del mes
            const monthAbbreviation = localEventStart.toLocaleDateString('en-US', { month: 'short' });

            // Obtener el día con sufijo ordinal
            const day = localEventStart.getDate(); // Día del mes
            const ordinal = day % 10 === 1 && day !== 11 ? 'st' : 
                  day % 10 === 2 && day !== 12 ? 'nd' :
                  day % 10 === 3 && day !== 13 ? 'rd' : 'th';

            // Formatear la fecha completa
            let formattedTime = `${timeString} on ${monthAbbreviation} ${day}${ordinal}, ${localEventStart.getFullYear()}`;

            setBookingFailedError(formattedTime);
          }

          setBookingFailed(true);
          return;
        }

        setBookingSuccess(true);

        setTimeout(() => {
          setBookingSuccess(false);
          setSelectedSlot(null);
          setUserEmail('');
          setView('options');
        }, 5000);

      } catch (error) {
        setBookingFailed(true);
      } finally {
        setIsBooking(false);
      }
    }
    else
      setEmailError(true);
  };

  const checkEmail = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let flag = re.test(String(userEmail).toLowerCase());

    if (userEmail !== "" && flag) {
      let i = 0; // Inicializamos el índice
      let formattedTime = null; // Para almacenar el rango de tiempo si se encuentra el evento

      while (i < events.length) {
        const event = events[i];
        const attendeesEmails = event.attendees.map(attendee => attendee.emailAddress.address.toLowerCase());

        if (attendeesEmails.includes(userEmail)) {
          const start = new Date(event.start.dateTime); // Esto te da la hora en UTC
          const localOffset = start.getTimezoneOffset() * 60000; // El offset es negativo si la zona local es adelante de UTC

          // Crear nuevas fechas ajustadas a la zona horaria local
          const localEventStart = new Date(start.getTime() - localOffset);
 
          // Opciones para la hora
          const timeString = localEventStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

          const monthAbbreviation = localEventStart.toLocaleDateString('en-US', { month: 'short' });

          // Opciones para la fecha con sufijos ordinales
          const day = localEventStart.getDate(); // Día del mes
          const ordinal = day % 10 === 1 && day !== 11 ? 'st' : 
                day % 10 === 2 && day !== 12 ? 'nd' :
                day % 10 === 3 && day !== 13 ? 'rd' : 'th';

          formattedTime = `${timeString} on ${monthAbbreviation} ${day}${ordinal}, ${localEventStart.getFullYear()}`;

          break; // Salimos del bucle si encontramos el email
        }
        i++;
      }

      if (formattedTime) {
        setUserEvent(formattedTime);
        return false;
      } else
        return true;
    }
    else
      return false;
  }

  return (
    <div className="chat-bubble-container">
      {isOpen && (
        <div className={"chat-window" + (!isLoading && view == 'calendar' ? " new-width" : "")}>
          <div className="chat-header">
            <h3>
              {view === 'options' && 'How can we help?'}
              {view === 'calendar' && 'Book a Call'}
              {view === 'chat' && 'Chat with us'}
            </h3>
            <button className="close-button" disabled={isBooking} onClick={() => {
              setIsOpen(false);
              setView('options');
              setUserEvent('');
              setEvents([]);
              setAvailableSlots([]);
              setBookingFailed(false);
              setBookingFailedError('');
              setBookingSuccess(false);
            }}>×</button>
          </div>
          <div className={"chat-content" + (!isLoading ? " pr" : "")}>
            {view === 'options' && (
              <>
                <button className="chat-option" onClick={handleBookCall}>Book a Call</button>
              </>
            )}

            {view === 'calendar' && (
              <>
                {
                  !isLoading ?
                    (
                      !bookingSuccess ?
                        <button className="back-button" onClick={handleBack} disabled={isBooking}>
                          <img src={arrowLeft} />
                          <label>Back</label>
                        </button>
                      : null
                    )
                    : null
                }
                <div className={"calendar-container" + (!isLoading ? " pr" : "")}>
                  {isLoading ? (
                    <Spinner myClass="containerSpinnerSecondary chat" />
                  ) : bookingSuccess ? (
                    <div className="booking-success">
                      <p>✅ Your call has been scheduled successfully!</p>
                      <p>You will receive an email confirmation shortly.</p>
                    </div>
                  ) : !bookingFailed ? (
                    <>
                      {selectedSlot ? (
                        <div className="booking-form">
                          <h4>Selected Time:</h4>
                          <p>
                            {selectedSlot.start.toLocaleString([], {
                              dateStyle: 'full',
                              timeStyle: 'short'
                            })}
                          </p>
                          <input
                            type="email"
                            placeholder="Enter your email"
                            value={userEmail}
                            onChange={(e) => {
                              setEmailError(false);
                              setUserEvent('');
                              setUserEmail(e.target.value);
                            }}
                            className={"email-input" + (emailError ? " mb10" : "")}
                            disabled={isBooking}
                          />
                          {emailError ? <label className='labelIncompleteData'>{userEmail === "" ? "The e-mail cannot be empty." : (userEvent === "" ? "Your e-mail must be valid." : "You have a call scheduled at " + userEvent )}</label> : null}
                          <div className="booking-actions">
                            <button
                              className="cancel-button"
                              onClick={() => {
                                setEmailError(false);
                                setSelectedSlot(null);
                                setView('options');
                                setEvents([]);
                                setUserEvent('');
                                setAvailableSlots([]);
                                setSelectedSlot(null);
                                setUserEmail('');
                                setBookingFailed(false);
                                setBookingFailedError('');
                              }}
                              disabled={isBooking}
                            >
                              Cancel
                            </button>
                            <button
                              className="book-button"
                              onClick={handleBookSlot}
                              disabled={isBooking}
                            >
                              {isBooking ? 'Booking...' : 'Book Call'}
                            </button>
                          </div>
                        </div>
                      ) : (
                        Object.entries(availableSlots.reduce((acc, slot) => {
                          const date = slot.start.toDateString();
                          if (!acc[date]) {
                            acc[date] = [];
                          }
                          acc[date].push(slot);
                          return acc;
                        }, {})).map(([date, slots]) => (
                          <div key={date} className="date-slot">
                            <h4>{new Date(date).toLocaleDateString()}</h4>
                            <div className="time-slots">
                              {slots.map((slot, index) => (
                                <button
                                  key={index}
                                  className="time-slot"
                                  onClick={() => handleSlotSelect(slot)}
                                >
                                  {slot.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} -&nbsp;
                                  {slot.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                </button>
                              ))}
                            </div>
                          </div>
                        ))
                      )}
                    </>
                  ) : 
                    <div className="booking-success">
                      <p>❌ Failed to book appointment.</p>
                      <p>{bookingFailedError === "" ? "Please try again!" : "You have a call scheduled at " + bookingFailedError}</p>
                    </div>
                  }
                </div>
              </>
            )}

            {view === 'chat' && (
              <>
                <button className="back-button" onClick={handleBack}>
                  <img src={arrowLeft} />
                  <label>Back</label>
                </button>
                <div className="chat-messages">
                  <div className="message system">
                    Hello! How can we assist you today?
                  </div>
                </div>
                <div className="chat-input-container">
                  <input
                    type="text"
                    placeholder="Type your message..."
                    className="chat-input"
                  />
                  <button className="send-button">Send</button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <button className="chat-bubble" onClick={() => setIsOpen(!isOpen)}>
        <span>💬</span>
      </button>
    </div>
  );
};

export default ChatBubble; 