import React, { Component } from 'react';
import headerImage from './images/header-image-overlay.jpg';
import PropTypes from 'prop-types';
import ReactGA4 from 'react-ga4';
import * as THREE from 'three';

const spanishHeroText = "Somos un equipo apasionado dedicado a crear herramientas innovadoras que potencien el éxito de tu negocio. Únete a nosotros en este emocionante viaje mientras redefinimos el futuro de los negocios, juntos.";
const englishHeroText = "We're a passionate team dedicated to crafting innovative tools that empower your business success. Join us on this exciting journey as we redefine the future of business, together."

function debounce(func, wait) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

class Header extends Component {
    constructor(props) {
        super(props);

        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        this.state = {
            displayText: "",
            language: this.props.language,
            finished: false,
            finishing: false,
            isMobile: isMobile
        }

        this.changeSection = this.changeSection.bind(this);
        this.loadText = this.loadText.bind(this);
        this.onClickLink = this.onClickLink.bind(this);
        this.mount = React.createRef();
    }

    changeSection() {
        this.props.changeCurrentSection("Home");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "Home", page: '/Home' });
    }

    onClickLink() {
        if (this.props.currentSection != "Contact") {
            this.props.changeCurrentSection("Contact");
            if (this.props.cookieUp)
                ReactGA4.send({ hitType: "pageview", title: "Contact", page: "/Contact" });
        }

        let element = document.getElementById("contacto");
        if (element != null)
            element.scrollIntoView();
    }

    loadText(text, language){
        this.setState({ finishing: true, displayText: "" })
        setTimeout(() => {
            let i = 0;

            const interval = setInterval(() => {
                if (language == this.state.language) {
                    if (i < text.length) {
                        this.setState({ displayText: this.state.displayText + text.charAt(i) })
                        i++;
                    } else
                        this.setState({ finished: true, finishing: false })
                }
                else
                    clearInterval(interval);
            }, 20);
        }, 2500);
    }

    setVhProperty() {
        const vh = window.innerHeight * 0.01; // Get the viewport height in pixels
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    handleMouseMove = (event) => {
        const mouseX = (event.clientX - window.innerWidth / 2) / 100;
        const mouseY = (event.clientY - window.innerHeight / 2) / 100;
        this.camera.position.x += (mouseX - this.camera.position.x) * 0.15;
        this.camera.position.y += (-mouseY - this.camera.position.y) * 0.15;
        this.camera.lookAt(this.scene.position);
    }

    simulateMobileAnimation = () => {
        let startTime = Date.now();
        const duration = 5000; // 5 seconds of animation
        const animate = () => {
            const elapsed = Date.now() - startTime;
            if (elapsed < duration) {
                // Simulate mouse movement in a circular pattern
                const progress = elapsed / duration;
                const mouseX = Math.sin(progress * Math.PI * 2) * window.innerWidth / 4;
                const mouseY = Math.cos(progress * Math.PI * 2) * window.innerHeight / 4;
                
                this.camera.position.x += (mouseX / 100 - this.camera.position.x) * 0.15;
                this.camera.position.y += (-mouseY / 100 - this.camera.position.y) * 0.15;
                this.camera.lookAt(this.scene.position);
                
                requestAnimationFrame(animate);
            }
        };
        animate();
    };

    componentDidMount(){
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(80, window.innerWidth / window.innerHeight, 0.1, 100);
        this.camera.position.set(-600, 0, -75);
    
        const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true }); 
        renderer.setSize(window.innerWidth, window.innerHeight);
        this.mount.current.appendChild(renderer.domElement);
    
        const material = new THREE.MeshBasicMaterial({ color: 0x26266b, wireframe: true, transparent: true, opacity: 0.5 }); 
        const geometry = new THREE.BoxBufferGeometry(256, 512, 256); 
    
        const light = new THREE.DirectionalLight(0xffffff, 1);
        light.position.set(1, 1, 1);
        this.scene.add(light);
    
        const instanceCount = 300; // Reduced the number of instances for better performance
    
        const instancedCubes = new THREE.InstancedMesh(geometry, material, instanceCount); 
        for (let i = 0; i < instanceCount; i++) {
            const matrix = new THREE.Matrix4();
            matrix.setPosition(i, 0, 100); // Multiply the X position by -1
            instancedCubes.setMatrixAt(i, matrix);
        }
        this.scene.add(instancedCubes);
    
        const instancedCubesLayer2 = new THREE.InstancedMesh(geometry, material, instanceCount); 
        for (let i = 0; i < instanceCount; i++) {
            const matrix = new THREE.Matrix4();
            matrix.setPosition(0, i, 200); // Multiply the Y position by -1
            instancedCubesLayer2.setMatrixAt(i, matrix);
        }
        this.scene.add(instancedCubesLayer2);
    
        const instancedCubesLayer3 = new THREE.InstancedMesh(geometry, material, instanceCount); 
        for (let i = 0; i < instanceCount; i++) {
            const matrix = new THREE.Matrix4();
            matrix.setPosition(-i, i, 300); // Multiply both X and Y positions by -1
            instancedCubesLayer3.setMatrixAt(i, matrix);
        }
        this.scene.add(instancedCubesLayer3);
    
        const instancedCubesLayer4 = new THREE.InstancedMesh(geometry, material, instanceCount); 
        for (let i = 0; i < instanceCount; i++) {
            const matrix = new THREE.Matrix4();
            matrix.setPosition(i, i - instanceCount, 400); // Multiply the X position by -1 and Y position by 1
            instancedCubesLayer4.setMatrixAt(i, matrix);
        }
        this.scene.add(instancedCubesLayer4);
    
        const animate = () => {
            requestAnimationFrame(animate);
            renderer.render(this.scene, this.camera);
        };
    
        animate();    
    
        const handleResize = debounce(() => {
            renderer.setSize(window.innerWidth, window.innerHeight);
            this.camera.aspect = window.innerWidth / window.innerHeight;
            this.camera.updateProjectionMatrix();
        }, 300); 
    
        if (!this.state.isMobile) {
            window.addEventListener('mousemove', this.handleMouseMove);
        } else {
            setTimeout(() => {
                this.simulateMobileAnimation();
            }, 100);
        }

        window.addEventListener('resize', handleResize);
    
        this.setVhProperty();
        window.addEventListener('resize', this.setVhProperty);  
    }

    componentWillUnmount() {
        if (!this.state.isMobile) {
            window.removeEventListener('mousemove', this.handleMouseMove);
        }
        window.removeEventListener('resize', this.setVhProperty);
    }

    render() {
        if (this.state.finished == false && this.state.finishing == false)
            this.loadText(
                this.props.language == "ES" ? spanishHeroText: englishHeroText, 
                this.props.language
            );
        else
            if (this.state.finishing) {
                if (this.state.language != this.props.language) {
                    this.setState({ language: this.props.language })
                    this.loadText(
                        this.props.language == "ES" ? spanishHeroText : englishHeroText,
                        this.props.language
                    );
                }
            }

        return (
            <section id="header" class="header" onMouseOver={this.props.currentSection != "Home" ? this.changeSection : () => { }}>
                <img
                    className="img-carousel"
                    src={headerImage}
                    alt="Header image"
                />
                <div class="three-cw-hero" ref={this.mount} />
                <div class="header-content">
                    <h3>
                        {
                            this.props.language == "ES"
                                ? "Lo que hacemos"
                                : "What we do"
                        }
                    </h3>
                    <div className="typing-demo">
                        <p>{!this.state.finished ? this.state.displayText : this.props.language == "ES" ? spanishHeroText : englishHeroText}</p>
                    </div>
                </div>
            </section>
        );
    }
}

Header.propTypes = {
    language: PropTypes.string.isRequired,
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired
};

export default Header;