import React, { Component } from 'react';
import { Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import ReactGA4 from 'react-ga4';
import {
    changeCurrentSection,
} from "../../../actions/generalActions";
import arrowLeft from '../../images/arrow-left.svg';
import calendar from '../../images/calendar.svg';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';

const texts = [
    "dotnet --version",
    "dotnet run",
    `dotnet new webapi -n OllamaChatApp
cd OllamaChatApp`,
    `using Microsoft.AspNetCore.Mvc;
using System.Diagnostics;

namespace OllamaChatApp.Controllers
{
    [ApiController]
    [Route("api/[controller]")]
    public class ChatController : ControllerBase
    {
        [HttpPost]
        public ActionResult<string> Post([FromBody] string message)
        {
            var process = new Process
            {
                StartInfo = new ProcessStartInfo
                {
                    FileName = "ollama",
                    Arguments = $"run llama3 {message}",
                    RedirectStandardOutput = true,
                    UseShellExecute = false,
                    CreateNoWindow = true
                }
            };

            process.Start();
            string result = process.StandardOutput.ReadToEnd();
            process.WaitForExit();

            return Ok(result);
        }
    }
}`,
    `var builder = WebApplication.CreateBuilder(args);

// Add services for controllers
builder.Services.AddControllers();  // Ensures that controllers like ChatController are recognized

// Add services for Swagger (optional)
builder.Services.AddEndpointsApiExplorer();
builder.Services.AddSwaggerGen();

var app = builder.Build();

// Configure the application pipeline
if (app.Environment.IsDevelopment())
{
    app.UseSwagger();
    app.UseSwaggerUI();
}

app.UseHttpsRedirection();

// Map controllers (this enables endpoints like /api/chat to work)
app.MapControllers();

app.Run();
`,
    "curl -X POST \"http://localhost:5000/api/chat\" -H \"Content-Type: application/json\" -d \"What is artificial intelligence?\""
];

const delay = ms => new Promise(res => setTimeout(res, ms));

const llaveizquierda = "{";
const llavederecha = "}";
const corcheteizquierdo = "[";
const corchederecho = "]";
const parentesisizquierdo = "(";
const parentesisderecho = ")";
const menor = "<";
const mayor = ">";

class HowToBuildAChatAppUsingOllama extends Component {
    constructor(props) {
        super(props);

        this.state = {
            codeCopied: 0
        }

        this.changeSection = this.changeSection.bind(this);
        this.onClickCopy = this.onClickCopy.bind(this);
    }

    changeSection() {
        this.props.changeCurrentSection("Blog");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "Blog", page: '/Blog' });
    }

    async onClickCopy(codeCopied, isJson) {
        if (isJson)
            navigator.clipboard.writeText(JSON.stringify(texts[codeCopied - 1], null, 2));
        else
            navigator.clipboard.writeText(texts[codeCopied - 1]);

        this.setState({ codeCopied: codeCopied });

        await delay(2000);

        this.setState({ codeCopied: 0 });
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>How to Build a Chat App Using Ollama</title>
                    <meta name="description" content="Combine the power of ollama with web technologies to create an intelligent chat application from scratch." />
                    <meta name="keywords" content="ollama, .NET, C#, AI, NLP, console application, language model, artificial intelligence" />
                    <meta property="og:title" content="How to Build a Chat App Using Ollama" />
                    <meta property="og:description" content="Combine the power of ollama with web technologies to create an intelligent chat application from scratch." />
                    <meta property="og:type" content="article" />
                    <meta property="article:published_time" content="2024-11-19" />
                </Helmet>
                <section class="article-section">
                    <div className='article-container'>
                        <div className='article-header'>
                            <a href='/blog'>
                                <div className='back-button-container'>
                                    <img src={arrowLeft} />
                                    <label>Back to Articles</label>
                                </div>
                            </a>
                        </div>
                        <div className='article'>
                            <h1>How to Build a Chat App Using Ollama</h1>
                            <div className='author-date-container'>
                                {/*  <div className='general-container'>
                          <img src={user} />
                          <label>Jonathan Confenti</label>
                        </div> */}
                                <div className='general-container'>
                                    <img src={calendar} />
                                    <label>November 19, 2024</label>
                                </div>
                            </div>
                            <div className='article-div'>
                                <p>
                                    In this article, we'll demonstrate how to create a simple chat application using Ollama in a .NET environment.
                                    This app will expose an endpoint that can receive a message and respond like a chat interface.
                                    We'll leverage Ollama to provide insightful responses to interesting queries.
                                </p>
                                <h2>Prerequisites</h2>
                                <div className='step'>
                                    <p className='mb-05'>
                                        Before you begin, make sure you have the following installed:
                                    </p>
                                    <ul class="custom-bullets">
                                        <li>.NET SDK (version 8.0)</li>
                                        <li>Visual Studio or any editor of your choice</li>
                                        <li>Ollama installed on your machine. For detailed instructions, refer to our previous article, <a href='/article#install-and-run-ollama-net'>Getting started with Ollama in .NET</a></li>
                                    </ul>
                                    <p>You can use the following command to verify the installed version of .NET.</p>
                                    <div className='bash'>
                                        <div className='bash-header'>
                                            <label>bash</label>
                                            <Button onClick={() => this.onClickCopy(1, false)}>
                                                <Icon className={this.state.codeCopied == 1 ? 'check' : ''} name={this.state.codeCopied == 1 ? 'check' : 'copy outline'} />
                                                <p>{this.state.codeCopied == 1 ? ' Copied!' : 'Copy'}</p>
                                            </Button>
                                        </div>
                                        <div className='bash-body'>
                                            <p>dotnet --version</p>
                                        </div>
                                    </div>
                                </div>
                                <h3>Step 1: Create a New Web API Project</h3>
                                <div className='step'>
                                    <p>We will start by creating a new ASP.NET Core Web API project. Open your terminal and run:</p>
                                    <div className='bash mt-10'>
                                        <div className='bash-header'>
                                            <label>bash</label>
                                            <Button onClick={() => this.onClickCopy(3, false)}>
                                                <Icon className={this.state.codeCopied == 3 ? 'check' : ''} name={this.state.codeCopied == 3 ? 'check' : 'copy outline'} />
                                                <p>{this.state.codeCopied == 3 ? ' Copied!' : 'Copy'}</p>
                                            </Button>
                                        </div>
                                        <div className='bash-body'>
                                            <p>dotnet new webapi -n OllamaChatApp</p>
                                            <p><span className='orange'>cd</span> OllamaChatApp</p>
                                        </div>
                                    </div>
                                    <p>This command generates a new web API project.</p>
                                </div>
                                <h3>Step 2: Set Up the Chat Controller</h3>
                                <div className='step'>
                                    <p>
                                        Create a new controller named <span>ChatController.cs</span> inside the <span>Controllers</span> folder.
                                        This controller will handle incoming chat messages and interact with Ollama.
                                    </p>
                                    <p>
                                        Add the following code to <span>ChatController.cs</span>:
                                    </p>
                                    <div className='bash'>
                                        <div className='bash-header'>
                                            <label>bash</label>
                                            <Button onClick={() => this.onClickCopy(4, false)}>
                                                <Icon className={this.state.codeCopied == 4 ? 'check' : ''} name={this.state.codeCopied == 4 ? 'check' : 'copy outline'} />
                                                <p>{this.state.codeCopied == 4 ? ' Copied!' : 'Copy'}</p>
                                            </Button>
                                        </div>
                                        <div className='bash-body'>
                                            <p><span className='blue'>using</span> Microsoft.AspNetCore.Mvc;</p>
                                            <p><span className='blue'>using</span> System.Diagnostics;</p>
                                            <br></br>
                                            <p><span className='blue'>namespace</span> <span className='red'>OllamaChatApp.Controllers</span></p>
                                            <p>{llaveizquierda}</p>
                                            <p className='one-space'>{corcheteizquierdo}<span className='grey'>ApiController</span>{corchederecho}</p>
                                            <p className='one-space'>{corcheteizquierdo}<span className='grey'>Route{parentesisizquierdo}</span><span className='green'>"api/[controller]"</span><span className='grey'>{parentesisderecho}</span>{corchederecho}</p>
                                            <p className='one-space'><span className='blue'>public class</span> <span className='red'>ChatController</span> : <span className='red'>ControllerBase</span></p>
                                            <p className='one-space'>{llaveizquierda}</p>
                                            <p className='two-spaces'>{corcheteizquierdo}<span className='grey'>HttpPost</span>{corchederecho}</p>
                                            <p className='two-spaces'><span className='blue'>public</span> ActionResult{menor}<span className='orange'>string</span>{mayor} <span className='red'>Post</span>{parentesisizquierdo}[FromBody] <span className='orange'>string</span> message{parentesisderecho}</p>
                                            <p className='two-spaces'>{llaveizquierda}</p>
                                            <p className='three-spaces'><span className='blue'>var</span> process = <span className='blue'>new</span> Process</p>
                                            <p className='three-spaces'>{llaveizquierda}</p>
                                            <p className='four-spaces'>StartInfo = <span className='blue'>new</span> ProcessStartInfo</p>
                                            <p className='four-spaces'>{llaveizquierda}</p>
                                            <p className='five-spaces'>FileName = <span className='green'>"ollama"</span>,</p>
                                            <p className='five-spaces'>Arguments = <span className='green'>$"run llama3 {llaveizquierda}message{llavederecha}"</span>,</p>
                                            <p className='five-spaces'>RedirectStandardOutput = <span className='blue'>true</span>,</p>
                                            <p className='five-spaces'>UseShellExecute = <span className='blue'>false</span>,</p>
                                            <p className='five-spaces'>CreateNoWindow = <span className='blue'>true</span></p>
                                            <p className='four-spaces'>{llavederecha}</p>
                                            <p className='three-spaces'>{llavederecha};</p>
                                            <br></br>
                                            <p className='three-spaces'>process.Start();</p>
                                            <p className='three-spaces'><span className='orange'>string</span> result = process.StandardOutput.ReadToEnd();</p>
                                            <p className='three-spaces'>process.WaitForExit();</p>
                                            <br></br>
                                            <p className='three-spaces'><span className='blue'>return</span> Ok(result);</p>
                                            <p className='two-spaces'>{llavederecha}</p>
                                            <p className='one-space'>{llavederecha}</p>
                                            <p>{llavederecha}</p>
                                        </div>
                                    </div>
                                    <p>This code defines a POST endpoint that receives a message, runs it through Ollama, and returns the response.</p>
                                </div>
                                <h3>Step 3: Configure the API to Run</h3>
                                <div className='step'>
                                    <p>
                                        Make sure the application is configured to run. Open <span>Program.cs</span> and replace it with the following code:
                                    </p>
                                    <div className='bash'>
                                        <div className='bash-header'>
                                            <label>csharp</label>
                                            <Button onClick={() => this.onClickCopy(5, false)}>
                                                <Icon className={this.state.codeCopied == 5 ? 'check' : ''} name={this.state.codeCopied == 5 ? 'check' : 'copy outline'} />
                                                <p>{this.state.codeCopied == 5 ? ' Copied!' : 'Copy'}</p>
                                            </Button>
                                        </div>
                                        <div className='bash-body'>
                                            <p><span className='blue'>var</span> builder = WebApplication.CreateBuilder(args);</p>
                                            <br></br>
                                            <p><span className='grey'>// Add services for controllers</span></p>
                                            <p>builder.Services.AddControllers(); <span className='grey'>// Ensures that controllers like ChatController are recognized</span></p>
                                            <br></br>
                                            <p><span className='grey'>// Add services for Swagger (optional)</span></p>
                                            <p>builder.Services.AddEndpointsApiExplorer();</p>
                                            <p>builder.Services.AddSwaggerGen();</p>
                                            <br></br>
                                            <p><span className='blue'>var</span> app = builder.Build();</p>
                                            <br></br>
                                            <p><span className='grey'>// Configure the application pipeline</span></p>
                                            <p><span className='blue'>if</span> (app.Environment.IsDevelopment())</p>
                                            <p>{llaveizquierda}</p>
                                            <p className='one-space'>app.UseSwagger();</p>
                                            <p className='one-space'>app.UseSwaggerUI();</p>
                                            <p>{llavederecha}</p>
                                            <br></br>
                                            <p>app.UseHttpsRedirection();</p>
                                            <br></br>
                                            <p><span className='grey'>// Map controllers (this enables endpoints like /api/chat to work)</span></p>
                                            <p>app.MapControllers();</p>
                                            <br></br>
                                            <p>app.Run();</p>
                                        </div>
                                    </div>
                                    <p>This code runs a language model in Ollama and displays the result in the console.</p>
                                </div>
                                <h3>Step 4: Test Your Chat Application</h3>
                                <div className='step'>
                                    <p>Run your application using the following command in the terminal:</p>
                                    <div className='bash'>
                                        <div className='bash-header'>
                                            <label>bash</label>
                                            <Button onClick={() => this.onClickCopy(2, false)}>
                                                <Icon className={this.state.codeCopied == 2 ? 'check' : ''} name={this.state.codeCopied == 2 ? 'check' : 'copy outline'} />
                                                <p>{this.state.codeCopied == 2 ? ' Copied!' : 'Copy'}</p>
                                            </Button>
                                        </div>
                                        <div className='bash-body'>
                                            <p>dotnet run</p>
                                        </div>
                                    </div>
                                    <p>You can test the endpoint using a tool like Postman or cURL. Here’s an example of how to send a POST request using cURL:</p>
                                    <div className='bash'>
                                        <div className='bash-header'>
                                            <label>bash</label>
                                            <Button onClick={() => this.onClickCopy(6, false)}>
                                                <Icon className={this.state.codeCopied == 6 ? 'check' : ''} name={this.state.codeCopied == 6 ? 'check' : 'copy outline'} />
                                                <p>{this.state.codeCopied == 6 ? ' Copied!' : 'Copy'}</p>
                                            </Button>
                                        </div>
                                        <div className='bash-body'>
                                            <p>curl -X POST <span className='green'>"http://localhost:5000/api/chat"</span> -H <span className='green'>"Content-Type: application/json"</span> -d <span className='green'>"\"What is artificial intelligence?\""</span></p>
                                        </div>
                                    </div>
                                    <p>You should receive a response from Ollama based on your query.</p>
                                </div>
                                <h3>Step 5: Improve Your Chat App</h3>
                                <div className='step'>
                                    <p>You can enhance your chat application by:</p>
                                    <ul class="custom-bullets">
                                        <li>Adding a simple front-end using HTML and JavaScript to interact with the API.</li>
                                        <li>Implementing user session management to keep track of conversations.</li>
                                        <li>Introducing more complex queries and responses, utilizing Ollama’s capabilities fully.</li>
                                    </ul>
                                </div>
                                <h3>Demo Video</h3>
                                <div className='step'>
                                    <p>To make the implementation clearer, here is a demonstration video showcasing the execution and results of the application:</p>
                                    <ReactPlayer
                                        url='../../build-a-chat-app.mp4'
                                        poster="../../portada2.png"
                                        controls={true}
                                        width='100%'
                                        height='auto'
                                        style={{
                                            borderRadius: '6px',
                                            background: 'linear-gradient(145deg, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.1) 100%)'
                                        }}
                                        config={{
                                            file: {
                                                attributes: {
                                                    poster: '../../portada2.png'
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                <h3>Conclusion</h3>
                                <div className='step'>
                                    <p>
                                        In this article, we’ve built a simple chat application using Ollama in a .NET environment.
                                        You now have a foundation to build upon, with the ability to enhance functionality and create a richer user experience.
                                    </p>
                                </div>
                                <h3>Source Code</h3>
                                <div className='last-step'>
                                    <p>
                                        You can find the complete source code for this project on GitHub. Feel free to explore, clone, and modify it for your own use: <a href='https://github.com/coowise/OllamaChatApp' target='_blank'>GitHub Repository: OllamaChatApp</a>
                                    </p>
                                    <p>
                                        This repository includes all the files and configurations used in this tutorial, making it easy for you to replicate and extend the application.
                                    </p>
                                </div>
                            </div>
                            <div className='footer'>
                                <span>AI Development</span>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

const mapStateToProps = (value) => {
    return {
        language: value.general.language,

        currentSection: value.general.currentSection,

        cookieUp: value.general.cookieUp
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HowToBuildAChatAppUsingOllama);